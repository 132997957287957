import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  baseURL = environment.apiURL + '/';

  constructor(private http: HttpClient) { }

  getAllIcons(){
    return this.http.get<any[]>(`${this.baseURL}icons`,{ withCredentials: true });
  }
}
