import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  baseURL = environment.apiURL + '/settings';

  private metaSettings = {
    componentTitle: {label: 'Component Title', type: 'text'},
    showComponentTitle: {label: 'Show Component Title?', type: 'switch'},
    expandable: {label: 'Expandable?', type: 'switch'},
    componentIconName: {label: 'Component Icon Name', type: 'text'},
    bannerDirection: {label: 'Banner Direction', type: 'radio', options: ['ltr', 'rtl']},
    hideWhenNoDataAvailable: {label: 'Hide When No Data Available?', type: 'switch'},
    hideStationName: {label: 'Hide Station Name?', type: 'switch'},
    radius: {label: 'Radius', type: 'number'},
    bindingField: {label: 'Binding Field', type: 'text'},
    style: {
      'hasChild': true,
      'color': {label: 'Color', type: 'text'},
      'font-style': {label: 'Font Style', type: 'radio', options: ['italic', 'underline']},
      'font-weight': {label: 'Font Weight', type: 'radio', options: ['bold', 'regular']},
      'text-align': {label: 'Text Align', type: 'radio', options: ['center', 'left', 'right']},
    },
    proximity: {label: 'Proximity', type: 'number'},
    mapOptions: {
      'hasChild': true,
      "zoom": {label: 'Zoom', type: 'number'},
      "mapTypeId": {label: 'Map Type ID', type: 'text'},
      "location": {
        'hasChild': true,
        "lat": {label: 'Latitude', type: 'text'}, 
        "lng": {label: 'Longitude', type: 'text'}
      },
      "icon": {label: 'Icon', type: 'text'}
    },
    gradient: {label: 'Gradient', type: 'text'},
    reverse: {label: 'Reverse?', type: 'switch'},
    startEmpty: {label: 'Start Empty?', type: 'switch'},
    timeLimit: {label: 'Time Limit', type: 'text'},
    score: {label: 'Score', type: 'number'},
    feedbackWrongTime: {label: 'Feedback Wrong Time', type: 'number'},
    feedbackRightTime: {label: 'Feedback Right Time', type: 'number'},
    showTimer: {label: 'Show Timer?', type: 'switch'},
    rightFeedbackAudio: {label: 'Right Feedback Audio', type: 'text'},
    wrongFeedbackAudio: {label: 'Wrong Feedback Audio', type: 'text'},
    services: {label: 'Services', type: 'text'},
    location: {label: 'Location', type: 'text'},
    groupCommentsPerPage: {label: 'Group Comments Per Page', type: 'number'},
    minimumCharacters: {label: 'Minimum Characters', type: 'number'},
    wordsQuantityEllipsis: {label: 'Words Quantity Ellipsis', type: 'number'},
    contextFilter: {label: 'Context Filter', type: 'text'},
    allowAnonymousUser: {label: 'Allow Anonymous User?', type: 'switch'},
    showDescription: {label: 'Show Description?', type: 'switch'},
    type: {label: 'Type', type: 'text'},
    logoUrl: {label: 'Logo Url', type: 'text'},
    showEditAction: {label: 'Show Edit Action?', type: 'switch'},
    showCertificateAction: {label: 'Show Certificate Action?', type: 'switch'},
    minimunWords: {label: 'Minimun Words', type: 'number'},
    cols: {label: 'Columns', type: 'number'},
    rows: {label: 'Rows', type: 'number'},
    showShuffleButton: {label: 'Show Shuffle Button?', type: 'switch'},
    showSolveButton: {label: 'Show Solve Button?', type: 'switch'},
    srcImage: {label: 'Image Source', type: 'text'},
    bindingField1: {label: 'Binding Field 1', type: 'text'},
    bindingField2: {label: 'Binding Field 2', type: 'text'},
    imageToRevealUrl: {label: 'Image To Reveal Url', type: 'text'},
    overlayColor: {label: 'Overlay Color', type: 'text'},
    overlayShape: {label: 'Overlay Shape', type: 'text'},
    placeholderField1: {label: 'Placeholder Field 1', type: 'text'},
    placeholderField2: {label: 'Placeholder Field 2', type: 'text'},
    size: {label: 'Size', type: 'text'},
    icon: {label: 'Icon', type: 'text'},
    text: {label: 'Text', type: 'text'},
    componentEventsToTrigger: {label: 'Component Events To Trigger', type: 'multiselectEvents'},
    goToPageId: {label: 'Go To Page Id', type: 'number'},
    eventName: {label: 'Event Name', type: 'text'},
    srcImage2: {label: 'Source Image 2', type: 'text'},
    interactionType: {label: 'Interaction Type', type: 'text'},
    fuzzy: {label: 'Fuzzy', type: 'number'},
    allowToggleMedia: {label: 'Allow Toggle Media?', type: 'switch'},
    initialToggleMedia: {label: 'Initial Toggle Media', type: 'text'},
    css: {label: 'CSS', type: 'text'},
    showImage: {label: 'Show Image?', type: 'switch'},
    infoOnlyTextFormat: {label: 'Info Only Text Format?', type: 'switch'},
    audioAutoPlay: {label: 'Audio Auto Play?', type: 'switch'},
    streetview: {label: 'Street View?', type: 'text'},
    
    infoComp: {'hasChild': true, 'isComponent': true},
    textualArrival: {'hasChild': true, 'isComponent': true},
    textualArrivalComp: {'hasChild': true, 'isComponent': true},
    verificationArrival: {'hasChild': true, 'isComponent': true},
    verificationArrivalComp: {'hasChild': true, 'isComponent': true},
    visualArrival: {'hasChild': true, 'isComponent': true},
    visualArrivalComp: {'hasChild': true, 'isComponent': true},
    overlayArrivalComp: {'hasChild': true, 'isComponent': true},
    missionComp: {'hasChild': true, 'isComponent': true},
    questionComp: {'hasChild': true, 'isComponent': true},
    feedbackComp: {'hasChild': true, 'isComponent': true},
    actionComp: {'hasChild': true, 'isComponent': true},
    actionButtonComp: {'hasChild': true, 'isComponent': true},
    commentComp: {'hasChild': true, 'isComponent': true},
    scientificDataCollectorComp: {'hasChild': true, 'isComponent': true},
    puzzleArrivalComp: {'hasChild': true, 'isComponent': true},
    imageComp: {'hasChild': true, 'isComponent': true},
    mediaGalleryComp: {'hasChild': true, 'isComponent': true},
    labelComp: {'hasChild': true, 'isComponent': true},
    embedComp: {'hasChild': true, 'isComponent': true},
    mapComp: {'hasChild': true, 'isComponent': true},
    timerComp: {'hasChild': true, 'isComponent': true},
    shareComp: {'hasChild': true, 'isComponent': true},
    ratingComp: {'hasChild': true, 'isComponent': true},
    uploadMediaComp: {'hasChild': true, 'isComponent': true},
    commentsGalleryComp: {'hasChild': true, 'isComponent': true},
    chartComp: {'hasChild': true, 'isComponent': true},
    voiceEmbedComp: {'hasChild': true, 'isComponent': true},
    logoComp: {'hasChild': true, 'isComponent': true},
  };

  constructor(private http: HttpClient) { }

  /* getCompSettings(){
    return of(this.metaSettings);
  } */

  getSettingsByType(type: string){
    return this.http.get<any>(`${this.baseURL}/type/${type}`,{ withCredentials: true });
  }

  updateSettingsByType(type: string, metadata: any){
    return this.http.put(`${this.baseURL}/type/${type}`, 
    {
      metadata: metadata
    }, { withCredentials: true }); 
  }

}
