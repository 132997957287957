import { Component } from '@angular/core';
import { User } from './objects/user';
import { AuthService } from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Station Card Builder';
  account: User = null;
  constructor(private accountService: AuthService) {
    this.accountService.account.subscribe((x) => {
      this.account = x;
    });
  }
}
