import { Component, OnInit, Input } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FrameService } from 'src/app/services/frame.service';

import { Frame } from './../../objects/frame';
import { ListsService } from 'src/app/objects/lists';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-frame-editor',
  templateUrl: './frame-editor.component.html',
  styleUrls: ['./frame-editor.component.css']
})
export class FrameEditorComponent implements OnInit {

  @Input() frame: any;
  @Input() formChanges: any;
  @Input() icons: any;
  frameValue: Frame = null;

  public events: any;
  public eventTypes: any;
  public userRoles: any;
  public sourceTypes: any;
  public fontFamilies: any;

  frameForm: FormGroup;
  errorMessage: string = '';
  showSpinnerForm: boolean = true;

  public stationId: any = null;
  public frameId: any = null;

  constructor(
    public frameService: FrameService, 
    public lists: ListsService, 
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute) { 
      this.route.queryParams.subscribe(params => {
        this.stationId = params['stationId'];
        this.frameId = params['id'];
      });
  }

  ngOnInit() {
    this.lists.getUserRolesList().subscribe((items: any) => {
      this.userRoles = items;
    });
    this.lists.getEventTypesList().subscribe((items: any) => {
      this.eventTypes = items;
    });
    this.lists.getSupportedFontFamilies().subscribe((items: any) => {
      this.fontFamilies = items;
    });
    this.settingsService.getSettingsByType('events_list').subscribe((res: any) => {
      if(res){
        this.events = JSON.parse(res['metadata']);
      }
    });
    this.lists.getSourceTypes().subscribe((items: any) => {
      this.sourceTypes = items;
    });
    if(this.frame){
      this.frame.subscribe((x: any) => {
        this.frameValue = x;
      });
    }
    this.populateForm();
    this.onFormValueChange();
  }

  onFormValueChange(){
    const initialValue = this.frameForm.value
    this.frameForm.valueChanges.subscribe(value => {
      let result = Object.keys(initialValue).some(key => this.frameForm.value[key] != initialValue[key]);
      //console.log(result);
      if(result){
        let value = this.formChanges.value;
        value.frameEdited = true;
        this.formChanges.next(value);
      }
    });
}

  handleCardStyleChange(event:any, source: string){
    let newValue = this.frameValue;
    if(source === 'style'){
      newValue.style = event.value;
    }else if(source === 'langType'){
      newValue.langType = event.value;
    }
    else if(source === 'frameBGColor'){
      newValue.frameBGColor = this.frameForm.value.frameBGColor;
    }
    else if(source === 'buttonTopLeftStyle'){
      newValue.buttonTopLeftStyle = this.frameForm.value.buttonTopLeftStyle;
    }
    else if(source === 'buttonTopRightStyle'){
      newValue.buttonTopRightStyle = this.frameForm.value.buttonTopRightStyle;
    }
    else if(source === 'buttonBottomLeftStyle'){
      newValue.buttonBottomLeftStyle = this.frameForm.value.buttonBottomLeftStyle;
    }
    else if(source === 'buttonBottomRightStyle'){
      newValue.buttonBottomRightStyle = this.frameForm.value.buttonBottomRightStyle;
    }
    else if(source === 'buttonsOpacity'){
      newValue.buttonsOpacity = this.frameForm.value.buttonsOpacity;
    }
    else if(source === 'componentsBgColor'){
      newValue.componentsBgColor = this.frameForm.value.componentsBgColor;
    }
    else if(source === 'componentsFontColor'){
      newValue.componentsFontColor = this.frameForm.value.componentsFontColor;
    }
    else if(source === 'frameNavType'){
      newValue.frameNavType = event.value;
    }
    this.frame.next(newValue);
  }

  populateForm(){
    this.showSpinnerForm = true;
    this.createForm();
    this.showSpinnerForm = false;
  }

  createForm() {
    let formGroup: any = {};
    for (let [key, value] of Object.entries(this.frameValue)) {
      formGroup[key] = new FormControl(this.frameValue[key], Validators.required);
    }
    this.frameForm = this.fb.group(formGroup);
  }

  saveForm(formValue: any){
    let metadata:any = {};
    metadata.langType = formValue.langType;
    metadata.style = formValue.style;
    metadata.pageAnimation = formValue.pageAnimation;
    metadata.textOnly = formValue.textOnly;
    metadata.buttonsType = formValue.buttonsType;
    metadata.exitMode = formValue.exitMode;
    metadata.exitText = formValue.exitText;
    metadata.closeButtonIcon = formValue.closeButtonIcon;
    metadata.closeButtonSize = formValue.closeButtonSize;
    metadata.timeLimit = formValue.timeLimit;
    metadata.buttonTopLeftStyle = formValue.buttonTopLeftStyle;
    metadata.buttonTopRightStyle = formValue.buttonTopRightStyle;
    metadata.buttonBottomLeftStyle = formValue.buttonBottomLeftStyle;
    metadata.buttonBottomRightStyle = formValue.buttonBottomRightStyle;
    metadata.buttonsOpacity = formValue.buttonsOpacity;
    metadata.buttonTimeHideDelay = formValue.buttonTimeHideDelay;
    metadata.componentsBgColor = formValue.componentsBgColor;
    metadata.componentsFontColor = formValue.componentsFontColor;
    metadata.componentsIconColor = formValue.componentsIconColor;
    metadata.componentsButtonColor = formValue.componentsButtonColor;
    metadata.hideComponentsShadow = formValue.hideComponentsShadow;
    metadata.hideHeaderCollapseComponents = formValue.hideHeaderCollapseComponents;
    metadata.hideIconOnHeaderCollapseComponents = formValue.hideIconOnHeaderCollapseComponents;
    let customEvents: any = {};
    this.eventTypes.forEach(element => {
      if(formValue[element.id] && formValue[element.id].length === 0){
        customEvents[element.id] = null;
      }else{
        customEvents[element.id] = formValue[element.id];
      }
    });
    metadata.customEvents = customEvents;

    metadata.customCss = JSON.parse(formValue.customCss);
    metadata.customCss = {};
    metadata.customCss['background-color'] = formValue.frameBGColor;
    metadata.customCss.componentHeader = {};
    metadata.customCss.componentHeader['font-size'] = formValue.componentsHFontSize;
    metadata.customCss.componentHeader['font-family'] = formValue.componentsHFontFamily;
    metadata.customCss.componentHeader['text-align'] = formValue.componentsHFontAlignment;
    metadata.customCss.componentHeader['line-height'] = formValue.componentsHLineHeight;
    metadata.customCss.componentHeader['letter-spacing'] = formValue.componentsHLetterSpacing;
    metadata.customCss.componentBody = {};
    metadata.customCss.componentBody['font-size'] = formValue.componentsBFontSize;
    metadata.customCss.componentBody['font-family'] = formValue.componentsBFontFamily;
    metadata.customCss.componentBody['text-align'] = formValue.componentsBFontAlignment;
    metadata.customCss.componentBody['line-height'] = formValue.componentsBLineHeight;
    metadata.customCss.componentBody['letter-spacing'] = formValue.componentsBLetterSpacing;
    
    metadata.delayedClosedCardTime = formValue.delayedClosedCardTime;
    metadata.delayCloseCardByEvent = formValue.delayCloseCardByEvent;
    metadata.frameNavType = formValue.frameNavType;
    metadata.allowEdit = formValue.allowEdit;
    metadata.emailLabel = formValue.emailLabel;
    metadata.usernameLabel = formValue.usernameLabel;
    metadata.passwordLabel = formValue.passwordLabel;
    metadata.loginLabel = formValue.loginLabel;
    metadata.registerLabel = formValue.registerLabel;
    metadata.submitLabel = formValue.submitLabel;
    if(formValue.allowEdit){
      metadata['appendComponent'] = {'type': 'action', 'userRole': formValue.userRole};
    }

    let updatedFrame:any = {};
    updatedFrame.description = formValue.description;
    updatedFrame.lang = formValue.lang;
    updatedFrame.metadata = JSON.stringify(metadata);
    //console.log(updatedFrame.metadata);//return;
    this.showSpinnerForm = true;
    this.frameService.updateFrame(updatedFrame, this.frameId).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The Frame is Updated Successfully!', { keepAfterRouteChange: true });
          if(this.frame){
            this.frame.next(response);
          }else{
            this.frame = new BehaviorSubject<any>(response);
          }
          this.router.navigate(['/editor'],{ queryParams: {id: this.frameId, action:'edit', stationId: this.stationId}});
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

}
