import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { AuthService } from 'src/app/services';
import { User } from 'src/app/objects/user';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  stationForm: FormGroup = null;
  stationId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showSpinner: boolean = true;
  account: User = null;
  frameId = null;
  constructor(private fb: FormBuilder, private router: Router,
    private activatedRoute: ActivatedRoute, private authService: AuthService) {
      this.activatedRoute.queryParams.subscribe(params => {
        if(params['id'] && params['id'] !== ''){
          this.frameId = params['id'];
        }
        if(params['stationId'] && params['stationId'] !== ''){
          this.stationId.next(params['stationId']);
          this.createForm();
        }
      });
    }

  ngOnChanges(){
  }

  ngOnInit() {
    this.showSpinner = false;
    this.authService.account.subscribe((x) => {
      this.account = x;
    });
    if(this.stationId.value === null){
      const queryParams: Params = { stationId: 43778  };
      this.router.navigate(
        [window.location.pathname], 
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        }
      );
    }
  }

  createForm() {
    if(this.stationId.value){
      this.stationForm = this.fb.group({
        id: [this.stationId.value]
      });
    }
  }

  handleStation(stationId: any){
    this.stationId.next(stationId);
    const queryParams: Params = { stationId: this.stationId.value  };
    this.router.navigate(
      [window.location.pathname], 
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      }
    );
  }

}
