import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Frame } from 'src/app/objects/frame';
import { FrameService } from 'src/app/services/frame.service';

import { Page } from 'src/app/objects/page';
import { PagesService } from 'src/app/services/pages.service';

import { Button } from 'src/app/objects/button';
import { ButtonsService } from 'src/app/services/buttons.service';

import { ComponentObj } from 'src/app/objects/componentObj';
import { ComponentsService } from 'src/app/services/components.service';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { ListsService } from 'src/app/objects/lists';
import { GeneralService } from 'src/app/services/general.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirm-dialog/confirmation-dialog.component';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  @ViewChild(MatTabGroup, { static: false }) tabGroup: MatTabGroup;
  public action: string = 'add';
  public frameID: string = null;
  public frame: BehaviorSubject<any | null> = null;

  public framePages: BehaviorSubject<any | null> = null;
  public page: BehaviorSubject<any | null> = null;

  public frameButtons: BehaviorSubject<any | null> = null;
  public buttonTypes: BehaviorSubject<any | null> = null;
  public button: BehaviorSubject<any | null> = null;

  public pageComponents: BehaviorSubject<any | null> = null;
  public comp: BehaviorSubject<any | null> = null;

  public formChanges: BehaviorSubject<any>;
  initialFormChanges: any = {
    'frameEdited': false,
    'pageEdited': false,
    'componentEdited': false,
    'buttonEdited': false
  };

  public icons: any = null;

  public eventTypes: any;
  public userRoles: any;
  showSpinner: boolean = true;
  public activeEditorTab = 0;

  constructor(
    public frameService: FrameService, 
    public pagesService: PagesService, 
    private settingsService: SettingsService,
    public buttonsService: ButtonsService, 
    public componentsService: ComponentsService, 
    private route: ActivatedRoute,
    public lists: ListsService,
    public generalService: GeneralService, private dialog: MatDialog) { 
    this.route.queryParams.subscribe(params => {
      this.action = params['action'];
      this.frameID = params['id'];
      const tabCount = 4;
      this.activeEditorTab = (params['activeTab'] ? params['activeTab'] : 0) % tabCount;
    });
    this.formChanges = new BehaviorSubject<any>(this.initialFormChanges);
  }

  ngOnChanges(): void {
    this.checkForUpdates();
  }

  checkForUpdates(){
    //refresh frame pages
    this.showSpinner = true;
    this.frameService.getFramePages(this.frameID).subscribe((res: any) => {
      if(res && res.length){
        this.framePages = new BehaviorSubject<Page[]>(res);
        let page: any;
        page = this.framePages.value[0];
        if(page['stationcard_page']){
          let pageColumns = page['stationcard_page'];
          page['description'] = pageColumns.description;
          page['type'] = pageColumns.type;
          let pageMeta = pageColumns.metadata;
          if(pageMeta){
            pageMeta = JSON.parse(pageMeta);
            page['title'] = ('title' in pageMeta) ? pageMeta['title'] : '';
          }
        }
        page['isDisplayFirst'] = Boolean(Number(page['isDisplayFirst']));
        if(page['customEvents']){
          let customEvents = JSON.parse(page['customEvents']);
          if(customEvents && ('events' in customEvents)){
            page['eventToListen'] = ('eventToListen' in customEvents.events[0]) ? customEvents.events[0]['eventToListen'] : null;
            page['action'] = ('action' in customEvents.events[0]) ? customEvents.events[0]['action'] : null;
          }
        }
        this.page = new BehaviorSubject<Page>(page);
        this.getPageComponents(page['pageId']);
      }else{
        this.framePages = new BehaviorSubject<Page[]>(null);
        this.page = new BehaviorSubject<Page>(null);
      }
      this.showSpinner = false;
    });

    //refresh frame buttons
    this.frameService.getFrameButtons(this.frameID).subscribe((res: any) => {
      if(res && res.length){
        this.frameButtons = new BehaviorSubject<Button[]>(res);
        this.button = new BehaviorSubject<Button>(this.frameButtons.value[0]);
        this.showSpinner = false;
      }else{
        this.frameButtons = new BehaviorSubject<Button[]>(null);
        this.button = new BehaviorSubject<Button>(null);
        this.showSpinner = false;
      }
    });

    //refresh button types
    this.buttonsService.getAllButtons().subscribe((res: any) => {
      //console.log(res);
      if(res && res.length){
        this.buttonTypes = new BehaviorSubject<Button[]>(res);
        this.showSpinner = false;
      }else{
        this.buttonTypes = new BehaviorSubject<Button[]>(null);
        this.showSpinner = false;
      }
    });

    if(this.page !== null){
      this.page.subscribe((x) => {
        this.getPageComponents(x['pageId']);
      });
    }

  }

  isLocalhost() {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  }

  change(index: number, oldIndex: number){
    //this.tabGroup.selectedIndex = oldIndex;
    return;
    const formValue = this.formChanges.value;
    if (formValue.frameEdited) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Confirm Tab Change',
          message: 'You have unsaved changes. Do you really want to switch tabs?',
        },
      });
      const res = dialogRef.afterClosed().subscribe(result => {
        if (result === 1) {
           //this.activeEditorTab = index;
           this.tabGroup.selectedIndex = index;
        } else {
           this.tabGroup.selectedIndex = oldIndex;
        }
        formValue.frameEdited = false;
        this.formChanges.next(formValue);
      });
    }else{
       //this.activeEditorTab = index;
       this.tabGroup.selectedIndex = index;
    }
  }

  ngOnInit() {
    this.lists.getEventTypesList().subscribe((items: any) => {
      this.eventTypes = items;
    });
    this.lists.getUserRolesList().subscribe((items: any) => {
      this.userRoles = items;
    });
    this.generalService.getAllIcons().subscribe((res: any) => {
      if(res && res.length){
        this.icons = res;
      }
    });
    if((this.action === 'edit' || this.action === 'addFrom') && this.frameID){
      this.showSpinner = true;
      this.frameService.getFrameById(this.frameID).subscribe((res: any) => {
        if(res){
          let frame: any = {};
          frame.description = res.description;
          frame.lang = res.lang;
          if(res.metadata){
            let metadata = JSON.parse(res.metadata);
            frame.langType = (metadata && metadata.langType) ? metadata.langType : 'ltr';
            frame.style = (metadata && metadata.style) ? metadata.style : 'cardShadow';
            frame.pageAnimation = (metadata && ("pageAnimation" in metadata)) ? metadata.pageAnimation : false;
            frame.textOnly = (metadata && ("textOnly" in metadata)) ? metadata.textOnly : false;
            frame.buttonsType = (metadata && metadata.buttonsType) ? metadata.buttonsType : 'cornerButtons';
            frame.exitMode = (metadata && metadata.exitMode) ? metadata.exitMode : 'closeButton';
            frame.exitText = (metadata && metadata.exitText) ? metadata.exitText : '';
            frame.closeButtonIcon = (metadata && metadata.closeButtonIcon) ? metadata.closeButtonIcon : '';
            frame.closeButtonSize = (metadata && metadata.closeButtonSize) ? metadata.closeButtonSize : 'large';
            frame.timeLimit = (metadata && metadata.timeLimit) ? metadata.timeLimit : '00:00:00';
            frame.frameBGColor = (metadata && metadata.customCss && metadata.customCss['background-color']) ? metadata.customCss['background-color'] : '';

            frame.componentsHFontSize = '12';
            frame.componentsHFontFamily = 'Arial, sans-serif';
            frame.componentsHFontAlignment = 'left';
            frame.componentsHLineHeight = '1';
            frame.componentsHLetterSpacing = '0';
            if(metadata.customCss && metadata.customCss.componentHeader){
              let compHeader = metadata.customCss.componentHeader;
              frame.componentsHFontSize = (compHeader['font-size']) ? compHeader['font-size'] : '12';
              frame.componentsHFontFamily = (compHeader['font-family']) ? compHeader['font-family'] : 'Arial, sans-serif';
              frame.componentsHFontAlignment = (compHeader['text-align']) ? compHeader['text-align'] : 'left';
              frame.componentsHLineHeight = (compHeader['line-height']) ? compHeader['line-height'] : '1';
              frame.componentsHLetterSpacing = (compHeader['letter-spacing']) ? compHeader['letter-spacing'] : '0';
            }

            frame.componentsBFontSize = '9';
            frame.componentsBFontFamily = 'Arial, sans-serif';
            frame.componentsBFontAlignment = 'left';
            frame.componentsBLineHeight = '1';
            frame.componentsBLetterSpacing = '0';
            if(metadata.customCss && metadata.customCss.componentBody){
              let compBody = metadata.customCss.componentBody;
              frame.componentsBFontSize = (compBody['font-size']) ? compBody['font-size'] : '9';
              frame.componentsBFontFamily = (compBody['font-family']) ? compBody['font-family'] : 'Arial, sans-serif';
              frame.componentsBFontAlignment = (compBody['text-align']) ? compBody['text-align'] : 'left';
              frame.componentsBLineHeight = (compBody['line-height']) ? compBody['line-height'] : '1';
              frame.componentsBLetterSpacing = (compBody['letter-spacing']) ? compBody['letter-spacing'] : '0';
            }
            frame.customCss = (metadata && metadata.customCss) ? JSON.stringify(metadata.customCss) : '';
            frame.buttonTopLeftStyle = (metadata && metadata.buttonTopLeftStyle) ? metadata.buttonTopLeftStyle : '';
            frame.buttonTopRightStyle = (metadata && metadata.buttonTopRightStyle) ? metadata.buttonTopRightStyle : '';
            frame.buttonBottomLeftStyle = (metadata && metadata.buttonBottomLeftStyle) ? metadata.buttonBottomLeftStyle : '';
            frame.buttonBottomRightStyle = (metadata && metadata.buttonBottomRightStyle) ? metadata.buttonBottomRightStyle : '';
            frame.buttonsOpacity = (metadata && metadata.buttonsOpacity) ? metadata.buttonsOpacity : 0.3;
            frame.buttonTimeHideDelay = (metadata && metadata.buttonTimeHideDelay) ? metadata.buttonTimeHideDelay : '00:00:00';
            frame.componentsBgColor = (metadata && metadata.componentsBgColor) ? metadata.componentsBgColor : '';
            frame.componentsFontColor = (metadata && metadata.componentsFontColor) ? metadata.componentsFontColor : '';
            frame.componentsIconColor = (metadata && metadata.componentsIconColor) ? metadata.componentsIconColor : '';
            frame.componentsButtonColor = (metadata && metadata.componentsButtonColor) ? metadata.componentsButtonColor : '';
            frame.hideComponentsShadow = (metadata && metadata.hideComponentsShadow) ? metadata.hideComponentsShadow : true;
            frame.hideHeaderCollapseComponents = (metadata && metadata.hideHeaderCollapseComponents) ? metadata.hideHeaderCollapseComponents : true;
            frame.hideIconOnHeaderCollapseComponents = (metadata && metadata.hideIconOnHeaderCollapseComponents) ? metadata.hideIconOnHeaderCollapseComponents : true;
            
            let customEvents: any = {};
            this.eventTypes.forEach(element => {
              frame[element.id] = (metadata && metadata.customEvents && metadata.customEvents[element.id]) ? metadata.customEvents[element.id] : null;
            });
            frame.customEvents = customEvents;

            frame.userRole = (metadata && metadata.appendComponent && metadata.appendComponent['userRole']) ? metadata.appendComponent['userRole'] : '';

            frame.allowEdit = (metadata && ("allowEdit" in metadata)) ? metadata.allowEdit : true;
            frame.emailLabel = (metadata && ("emailLabel" in metadata)) ? metadata.emailLabel : 'Email';
            frame.usernameLabel = (metadata && ("usernameLabel" in metadata)) ? metadata.usernameLabel : 'Username';
            frame.passwordLabel = (metadata && ("passwordLabel" in metadata)) ? metadata.passwordLabel : 'Password';
            frame.loginLabel = (metadata && ("loginLabel" in metadata)) ? metadata.loginLabel : 'Login';
            frame.registerLabel = (metadata && ("registerLabel" in metadata)) ? metadata.registerLabel : 'Register';
            frame.submitLabel = (metadata && ("submitLabel" in metadata)) ? metadata.submitLabel : 'Submit';
            frame.delayedClosedCardTime = (metadata && metadata.delayedClosedCardTime) ? metadata.delayedClosedCardTime : '';
            frame.delayCloseCardByEvent = (metadata && metadata.delayCloseCardByEvent) ? metadata.delayCloseCardByEvent : '';
            frame.frameNavType = (metadata && metadata.frameNavType) ? metadata.frameNavType : null;
          }
          this.frame = new BehaviorSubject<any>(frame);
          this.showSpinner = false;
        }else{
          this.frame = new BehaviorSubject<any>(null);
          this.showSpinner = false;
        }
      });
  
      this.checkForUpdates();

    }else if(this.action === 'addFrom' && this.frameID){
      this.showSpinner = true;
      this.frameService.getFrameById(this.frameID).subscribe((res: any) => {
        if(res){
          this.frame = new BehaviorSubject<Frame>(res);
          this.showSpinner = false;
        }else{
          this.frame = new BehaviorSubject<Frame>(null);
          this.showSpinner = false;
        }
      });

    }else{

      this.settingsService.getSettingsByType('frame').subscribe((res: any) => {
        this.showSpinner = true;
        if(res && (typeof(res) === 'object')){
          let frameMeta = JSON.parse(res.metadata);
          this.eventTypes.forEach(element => {
            frameMeta[element.id] = (frameMeta && frameMeta.customEvents && frameMeta.customEvents[element.id]) ? frameMeta.customEvents[element.id] : null;
          });
          frameMeta.userRole = (frameMeta && frameMeta.appendComponent && frameMeta.appendComponent['userRole']) ? frameMeta.appendComponent['userRole'] : '';
          frameMeta.frameBGColor = (frameMeta.customCss && frameMeta.customCss['background-color']) ? frameMeta.customCss['background-color'] : '';
          frameMeta.componentsHFontSize = '24';
          frameMeta.componentsHFontFamily = 'Palatino';
          frameMeta.componentsHFontAlignment = 'left';
          frameMeta.componentsHLineHeight = '24';
          frameMeta.componentsHLetterSpacing = '0';
          if(frameMeta.customCss && frameMeta.customCss.componentHeader){
            let compHeader = frameMeta.customCss.componentHeader;
            frameMeta.componentsHFontSize = (compHeader['font-size']) ? compHeader['font-size'] : '24';
            frameMeta.componentsHFontFamily = (compHeader['font-family']) ? compHeader['font-family'] : 'Palatino';
            frameMeta.componentsHFontAlignment = (compHeader['text-align']) ? compHeader['text-align'] : 'left';
            frameMeta.componentsHLineHeight = (compHeader['line-height']) ? compHeader['line-height'] : '24';
            frameMeta.componentsHLetterSpacing = (compHeader['letter-spacing']) ? compHeader['letter-spacing'] : '0';
          }

          frameMeta.componentsBFontSize = '16';
          frameMeta.componentsBFontFamily = 'Palatino';
          frameMeta.componentsBFontAlignment = 'left';
          frameMeta.componentsBLineHeight = '19';
          frameMeta.componentsBLetterSpacing = '0';
          if(frameMeta.customCss && frameMeta.customCss.componentBody){
            let compBody = frameMeta.customCss.componentBody;
            frameMeta.componentsBFontSize = (compBody['font-size']) ? compBody['font-size'] : '16';
            frameMeta.componentsBFontFamily = (compBody['font-family']) ? compBody['font-family'] : 'Palatino';
            frameMeta.componentsBFontAlignment = (compBody['text-align']) ? compBody['text-align'] : 'left';
            frameMeta.componentsBLineHeight = (compBody['line-height']) ? compBody['line-height'] : '19';
            frameMeta.componentsBLetterSpacing = (compBody['letter-spacing']) ? compBody['letter-spacing'] : '0';
          }
          frameMeta.customCss = (frameMeta && frameMeta.customCss) ? JSON.stringify(frameMeta.customCss) : '';
          if(this.frame){
            this.frame.next(frameMeta);
          }else{
            this.frame = new BehaviorSubject<any>(frameMeta);
          }
        }
        this.showSpinner = false;
      });

      this.pagesService.getEmptyPage().subscribe((res: any) => {
        this.page = new BehaviorSubject<any>(res);
        this.showSpinner = false;
      });

    }
  }

  getPageComponents(pageID){
    this.showSpinner = true;
    this.pagesService.getPageComponents(pageID).subscribe((res: any) => {
      if(res && res.length){
        let pageComponents = res;
        pageComponents.forEach((element: any) => {
          if('stationcard_component' in element){
            element.name = element['stationcard_component'].name;
            element.description = element['stationcard_component'].description;
            element.type = element['stationcard_component'].type;
          }
        });
        this.pageComponents = new BehaviorSubject<ComponentObj[]>(res);
        this.comp = new BehaviorSubject<ComponentObj>(pageComponents[0]);
        this.showSpinner = false;
      }else{
        this.pageComponents = new BehaviorSubject<ComponentObj[]>(null);
        this.comp = new BehaviorSubject<ComponentObj[]>(null);
        this.showSpinner = false;
      }
      
    });
    
  }

}
