import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Frame } from '../objects/frame';
import { Page } from '../objects/page';

@Injectable({
  providedIn: 'root'
})
export class FrameService {

  baseURL = environment.apiURL + '/frames';

  constructor(private http: HttpClient) { }

  getAllFrames(){
    return this.http.get<Frame[]>(`${this.baseURL}`,{ withCredentials: true });
  }

  getFrameById(id: string){
    return this.http.get<Frame>(`${this.baseURL}/${id}`,{ withCredentials: true });
  }

  addFrame(frame: any){
    return this.http.post(`${this.baseURL}/`, 
    {
      description: frame.description, 
      lang: frame.lang,
      sourceId: frame.sourceId,
      sourceType: frame.sourceType,
      metadata: frame.metadata
    }, { withCredentials: true }); 
  }

  copyFrameById(frameId: any){
    return this.http.post(`${this.baseURL}/copyFrom/${frameId}`, 
    {}, { withCredentials: true }); 
  }
  
  addFramePage(frameId: string, page: any){
    return this.http.post(`${this.baseURL}/${frameId}/page`, 
    {
      frameId: parseInt(frameId),
      description: page.description, 
      type: page.type,
      metadata: page.metadata,
      isDisplayFirst: page.isDisplayFirst,
      triggerEvent1: page.triggerEvent1,
      triggerEvent2: page.triggerEvent2,
      eventToListenToShow: page.eventToListenToShow,
      eventToListenToHide: page.eventToListenToHide,
      eventToListenToEnable: page.eventToListenToEnable,
      eventToListenToDisable: page.eventToListenToDisable,
      customEvents: page.customEvents,
    }, { withCredentials: true }); 
  }

  updateFramePage(frameId: string, pageId: string, page: any){
    return this.http.post(`${this.baseURL}/${frameId}/page/${pageId}`, 
    {
      frameId: parseInt(frameId),
      pageId: parseInt(pageId),
      description: page.description, 
      type: page.type,
      metadata: page.metadata,
      isDisplayFirst: page.isDisplayFirst,
      triggerEvent1: page.triggerEvent1,
      triggerEvent2: page.triggerEvent2,
      eventToListenToShow: page.eventToListenToShow,
      eventToListenToHide: page.eventToListenToHide,
      eventToListenToEnable: page.eventToListenToEnable,
      eventToListenToDisable: page.eventToListenToDisable,
      customEvents: page.customEvents,
    }, { withCredentials: true }); 
  }

  addFrameButton(frameId: string, button: any){
    return this.http.post(`${this.baseURL}/${frameId}/button`, 
    {
      frameId: parseInt(frameId),
      goToPageId: parseInt(button.goToPageId), 
      buttonId: parseInt(button.buttonId),
      position: button.position,
      metadata: button.metadata,
      eventToListenToShow: button.eventToListenToShow,
      eventToListenToHide: button.eventToListenToHide,
      eventToListenToEnable: button.eventToListenToEnable,
      eventToListenToDisable: button.eventToListenToDisable
    }, { withCredentials: true }); 
  }

  updateFrameButton(frameId: string, buttonId: string, button: any){
    return this.http.post(`${this.baseURL}/${frameId}/button/${buttonId}`, 
    {
      frameId: parseInt(frameId),
      buttonId: parseInt(button.buttonId),
      goToPageId: button.goToPageId,
      position: button.position,
      metadata: button.metadata,
      eventToListenToShow: button.eventToListenToShow,
      eventToListenToHide: button.eventToListenToHide,
      eventToListenToEnable: button.eventToListenToEnable,
      eventToListenToDisable: button.eventToListenToDisable
    }, { withCredentials: true }); 
  }

  addFrameButtonsMenu(frameId: string, data: any){
    return this.http.post(`${this.baseURL}/${frameId}/buttonsMenu`, 
    {
      frameId: parseInt(frameId),
      menuType: data.menuType, 
      collapsiblePosition: data.collapsiblePosition,
      menuLocation: data.menuLocation,
      menuDirection: data.menuDirection,
      backgroundColor: data.backgroundColor,
      colorOpacity: parseFloat(data.colorOpacity),
      buttons: data.buttons
    }, { withCredentials: true }); 
  }

  updateFrameButtonsMenu(frameId: string, buttonsMenuId: string, data: any){
    return this.http.post(`${this.baseURL}/${frameId}/buttonsMenu/${buttonsMenuId}`, 
    {
      frameId: parseInt(frameId),
      buttonsMenuId: parseInt(buttonsMenuId),
      menuType: data.menuType, 
      collapsiblePosition: data.collapsiblePosition,
      menuLocation: data.menuLocation,
      menuDirection: data.menuDirection,
      backgroundColor: data.backgroundColor,
      colorOpacity: parseFloat(data.colorOpacity),
      buttons: data.buttons
    }, { withCredentials: true }); 
  }

  updateFrame(frame: any, frameId: string){
    return this.http.put(`${this.baseURL}/${frameId}`, 
    {
      description: frame.description, 
      lang: frame.lang,
      sourceId: frame.sourceId,
      sourceType: frame.sourceType,
      metadata: frame.metadata
    }, { withCredentials: true }); 
  }

  deleteFrame(frameId: string){
    return this.http.delete(`${this.baseURL}/${frameId}`, { withCredentials: true }); 
  }

  deleteFramePage(frameId: string, pageId: string){
    return this.http.delete(`${this.baseURL}/${frameId}/page/${pageId}`, { withCredentials: true }); 
  }

  deleteFrameButton(frameId: string, buttonId: string){
    return this.http.delete(`${this.baseURL}/${frameId}/button/${buttonId}`, { withCredentials: true }); 
  }

  getStationData(stationId: Number){
    return this.http.post(`${this.baseURL}/station/${stationId}`, {}, { withCredentials: true }); 
  }

  getFramePages(frameID: string){
    return this.http.get<Page[]>(`${this.baseURL}/${frameID}/pages`, { withCredentials: true });
  }

  getFrameButtons(frameID: string){
    return this.http.get<Page[]>(`${this.baseURL}/${frameID}/buttons`, { withCredentials: true });
  }

  getFrameButtonsMenu(frameID: string){
    return this.http.get<any>(`${this.baseURL}/${frameID}/buttonsMenu`, { withCredentials: true });
  }

  getFrameApps(frameID: string){
    return this.http.get<any[]>(`${this.baseURL}/${frameID}/apps`, { withCredentials: true });
  }

  getFrameAppById(id: string){
    return this.http.get<any>(`${this.baseURL}/app/${id}`,{ withCredentials: true });
  }

  addFrameApp(frameId: string, frameApp: any){
    return this.http.post(`${this.baseURL}/${frameId}/app`, 
    {
      frameId: parseInt(frameId),
      sourceId: parseInt(frameApp.sourceId),
      sourceType: frameApp.sourceType,
      lang: frameApp.lang
    }, { withCredentials: true }); 
  }

  updateFrameApp(frameApp: any, assocId: string){
    return this.http.post(`${this.baseURL}/app/${assocId}`, 
    {
      sourceId: parseInt(frameApp.sourceId),
      sourceType: frameApp.sourceType,
      lang: frameApp.lang
    }, { withCredentials: true }); 
  }

  deleteFrameApp(assocId: string){
    return this.http.delete(`${this.baseURL}/app/${assocId}`, { withCredentials: true }); 
  }

}
