import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ListsService } from 'src/app/objects/lists';
import { AlertService } from 'src/app/services/alert.service';
import { ComponentsService } from 'src/app/services/components.service';
import { GeneralService } from 'src/app/services/general.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  componentsList: any = null;
  comp: any = null;
  compID: any = null;
  compSettingsForm: FormGroup = null;
  compSettings: {} = null;
  buttonPresets: any = null;
  buttonPreset: any = null;
  buttonPresetID: any = null;
  button: any = null;
  buttonID: any = null;
  buttonForm: FormGroup = null;
  showSpinner: boolean = true;
  public currentGroup = '';
  formFields: any = null;
  public events: any;
  public shareServices: any;
  public bindingFieldsList: any;
  public bindingFields12List: any;
  public eventTypes: any;
  public userRoles: any;
  public icons: any = null;
  public sourceTypes: any;
  public stationId: any = null;
  public buttonActionTypes: any;
  public fontFamilies: any;

  frameSettingsForm: FormGroup = null;
  frameSettings: any = null;

  constructor(
    private fb: FormBuilder, 
    private settingsService: SettingsService,
    private generalService: GeneralService,
    public componentsService: ComponentsService,
    private alertService: AlertService,
    public lists: ListsService,
    private route: ActivatedRoute,
    private router: Router) { 
      this.route.queryParams.subscribe(params => {
        this.stationId = params['stationId'];
      });
    }

  ngOnInit() {
    this.lists.getUserRolesList().subscribe((items: any) => {
      this.userRoles = items;
    });
    this.lists.getEventTypesList().subscribe((items: any) => {
      this.eventTypes = items;
    });
    this.settingsService.getSettingsByType('events_list').subscribe((res: any) => {
      if(res){
        this.events = JSON.parse(res['metadata']);
      }
    });
    this.lists.getSupportedFontFamilies().subscribe((items: any) => {
      this.fontFamilies = items;
    });
    this.lists.getShareServicesList().subscribe((items: any) => {
      this.shareServices = items;
    });
    this.lists.getButtonActionTypesList().subscribe((items: any) => {
      this.buttonActionTypes = items;
    });
    this.lists.getBindingFieldsList().subscribe((items: any) => {
      this.bindingFieldsList = items;
    });
    this.lists.getBindingFields12List().subscribe((items: any) => {
      this.bindingFields12List = items;
    });
    this.lists.getSourceTypes().subscribe((items: any) => {
      this.sourceTypes = items;
    });

    this.generalService.getAllIcons().subscribe((res: any) => {
      if(res && res.length){
        this.icons = res;
      }
    });

    this.settingsService.getSettingsByType('meta_fields').subscribe((res: any) => {
      if(res){
        this.compSettings = JSON.parse(res['metadata']);
      }
    });

    this.settingsService.getSettingsByType('button_presets').subscribe((res: any) => {
      if(res){
        this.buttonPresets = JSON.parse(res['metadata']);
        this.buttonPreset = this.buttonPresets[0];
        this.buttonPresetID = 0;
        this.button = this.buttonPreset.buttons[0];
        this.buttonID = 0;
        this.createButtonForm();
        this.showSpinner = false;
      }
    });

    this.componentsService.getAllComponents().subscribe((res: any) => {
      if(res && res.length){
        this.componentsList = res;
        this.comp = this.componentsList[0];
        this.compID = this.comp.id;
        this.createForm();
        this.showSpinner = false;
      }else{
        this.componentsList = null;
        this.comp = null;
        this.compID = null;
        this.showSpinner = false;
      }
    });

    this.settingsService.getSettingsByType('frame').subscribe((res: any) => {
      this.showSpinner = true;
      if(res && (typeof(res) === 'object')){
        this.frameSettings = res;
      }else{
        this.frameSettings = null;
      }
      if(this.frameSettings !== null){
        let frameMeta = JSON.parse(this.frameSettings.metadata);

        frameMeta.frameBGColor = (frameMeta.customCss && frameMeta.customCss['background-color']) ? frameMeta.customCss['background-color'] : '';

        if(frameMeta.customCss && frameMeta.customCss.componentHeader){
          let compHeader = frameMeta.customCss.componentHeader;
          frameMeta.componentsHFontSize = (compHeader['font-size']) ? compHeader['font-size'] : '24';
          frameMeta.componentsHFontFamily = (compHeader['font-family']) ? compHeader['font-family'] : 'Palatino';
          frameMeta.componentsHFontAlignment = (compHeader['text-align']) ? compHeader['text-align'] : 'left';
          frameMeta.componentsHLineHeight = (compHeader['line-height']) ? compHeader['line-height'] : '24';
          frameMeta.componentsHLetterSpacing = (compHeader['letter-spacing']) ? compHeader['letter-spacing'] : '0';
        }

        if(frameMeta.customCss && frameMeta.customCss.componentBody){
          let compBody = frameMeta.customCss.componentBody;
          frameMeta.componentsBFontSize = (compBody['font-size']) ? compBody['font-size'] : '16';
          frameMeta.componentsBFontFamily = (compBody['font-family']) ? compBody['font-family'] : 'Palatino';
          frameMeta.componentsBFontAlignment = (compBody['text-align']) ? compBody['text-align'] : 'left';
          frameMeta.componentsBLineHeight = (compBody['line-height']) ? compBody['line-height'] : '19';
          frameMeta.componentsBLetterSpacing = (compBody['letter-spacing']) ? compBody['letter-spacing'] : '0';
        }

        this.eventTypes.forEach(element => {
          frameMeta[element.id] = (frameMeta && frameMeta.customEvents && frameMeta.customEvents[element.id]) ? frameMeta.customEvents[element.id] : null;
        });
        frameMeta.userRole = (frameMeta && frameMeta.appendComponent && frameMeta.appendComponent['userRole']) ? frameMeta.appendComponent['userRole'] : '';
        frameMeta.customCss = (frameMeta && frameMeta.customCss) ? JSON.stringify(frameMeta.customCss) : '';
        let formGroup: any = {};
        for (let [key, value] of Object.entries(frameMeta)) {
          formGroup[key] = new FormControl(frameMeta[key]);
        }
        this.frameSettingsForm = this.fb.group(formGroup);
      }
      this.showSpinner = false;
    });
  }

  createForm() {
    let formGroup: any = {};
    let formFields: any = [];
    let compMeta = JSON.parse(this.comp.metadata);
    for (let [key, value] of Object.entries(compMeta)) {
      let entry = this.compSettings[key];
      if('hasChild' in entry){
        if('isComponent' in entry){
          let innerObj = compMeta[key];
          for (let [key1, value1] of Object.entries(innerObj)) {
            if(typeof innerObj[key1] === 'object'){
              let innerInnerObj = innerObj[key1];
              for (let [key2, value2] of Object.entries(innerInnerObj)) {
                if(key2 !== 'hasChild' && (key2 in innerInnerObj)){
                  let field: any = {};
                  if(key in this.compSettings && typeof innerObj[key1] !== 'object'){
                    entry = this.compSettings[key];
                    formGroup[key1] = new FormControl(innerObj[key1], Validators.required);
                    field['formControl'] = key1;
                    field['label'] = entry[key1].label;
                    field['type'] = entry[key1].type;
                    field['value'] = innerObj[key1];
                    if(entry[key1].options){
                      field['options'] = entry[key1].options;
                    }
                    if(key !== this.currentGroup){
                      this.currentGroup = key;
                      field['groupTitle'] = key;
                    }
                    field['group'] = key;
                    formFields.push(field);
                  }
                }
              }
            }else{
              let field: any = {};
              entry = this.compSettings[key1];
              formGroup[key+'.'+key1] = new FormControl(compMeta[key][key1], Validators.required);
              field['formControl'] = key+'.'+key1;
              field['label'] = entry.label;
              field['type'] = entry.type;
              field['value'] = compMeta[key][key1];
              if(entry.options){
                field['options'] = entry.options;
              }
              if(key !== this.currentGroup){
                this.currentGroup = key;
                field['groupTitle'] = key;
              }
              field['group'] = key;
              formFields.push(field);
            }
          }
        }else{
          let innerObj = compMeta[key];
          for (let [key1, value1] of Object.entries(innerObj)) {
            if(key1 !== 'hasChild' && (key1 in innerObj) && typeof compMeta[key] === 'object'){
              let field: any = {};
              if(key in this.compSettings && typeof innerObj[key1] !== 'object'){
                entry = this.compSettings[key];
                formGroup[key1] = new FormControl(innerObj[key1], Validators.required);
                field['formControl'] = key1;
                field['label'] = entry[key1].label;
                field['type'] = entry[key1].type;
                field['value'] = innerObj[key1];
                if(entry[key1].options){
                  field['options'] = entry[key1].options;
                }
                field['group'] = key;
                formFields.push(field);
              }
            }
          }
        }
      }else{
        let field: any = {};
        formGroup[key] = new FormControl(compMeta[key], Validators.required);
        field['formControl'] = key;
        field['label'] = entry.label;
        field['type'] = entry.type;
        field['value'] = compMeta[key];
        if(entry.options){
          field['options'] = entry.options;
        }
        formFields.push(field);
      }
    }
    this.formFields = formFields;
    this.compSettingsForm = this.fb.group(formGroup);
  }

  createButtonForm() {
    let formGroup: any = {};
    let formFields: any = [];
    for (let [key, value] of Object.entries(this.button)) {
      let field: any = {};
      formGroup[key] = new FormControl(value, Validators.required);
      formFields.push(field);
    }
    this.buttonForm = this.fb.group(formGroup);
  }

  saveForm(formValue: any){
    let metadata = {};
    let compMeta = JSON.parse(this.comp.metadata);
    for (let [key, value] of Object.entries(compMeta)) {
      let innerObj = compMeta[key];
      if(typeof innerObj === 'object'){
        let innerInnerObj = {};
        for (let [key1, value1] of Object.entries(innerObj)) {
          if((key+'.'+key1) in formValue){
            innerInnerObj[key1] = formValue[key+'.'+key1];
          }else{
            innerInnerObj[key1] = formValue[key1];
          }
        }
        metadata[key] = innerInnerObj;
      }else{
        metadata[key] = formValue[key];
      }
    }
    metadata = JSON.stringify(metadata);
    this.showSpinner = true;
    //console.log(metadata);
    //return;
    this.componentsService.updateComponentMeta(metadata, this.compID).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The component meta is successfully updated!', { keepAfterRouteChange: true });
          const queryParams: Params = { stationId: this.stationId};
          this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: queryParams, 
              queryParamsHandling: 'merge',
            }
          );
          this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  saveButtonForm(formValue: any){
    let index = this.buttonPresets[this.buttonPresetID].buttons.indexOf(this.button);
    if (index !== -1) {
      this.buttonPresets[this.buttonPresetID].buttons[index] = formValue;
    }
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinner = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
          this.router.navigate(['/settings'],{});
          this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    let buttonsArray = this.buttonPresets[this.buttonPresetID].buttons;
    buttonsArray[event.previousIndex].position = 'M'+ (event.currentIndex);
    buttonsArray[event.currentIndex].position = 'M'+ (event.previousIndex);
    moveItemInArray(buttonsArray, event.previousIndex, event.currentIndex);
    this.showSpinner = true;
    
    this.buttonPresets[this.buttonPresetID].buttons = buttonsArray;
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinner = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
        this.buttonPresets = JSON.parse(response['metadata']);
        this.buttonPreset = this.buttonPresets[this.buttonPresetID];
        this.button = this.buttonPreset.buttons[0];
        this.buttonID = 0;
        this.createButtonForm();
        this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
        this.router.navigate(['/settings'],{});
        this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  handleAddButton(){
    this.showSpinner = true;
    let currentButtons = this.buttonPresets[this.buttonPresetID].buttons;
    this.buttonPresets[this.buttonPresetID].buttons.push({
      "position": 'M'+ (currentButtons.length),
      "eventToListenToShow": null,
      "eventToListenToHide": null,
      "eventToListenToEnable": null,
      "eventToListenToDisable": null,
      "actionType": "gotoPage",
      "buttonText": "Location",
      "buttonIcon": "fa-map",
      "buttonBGColor": "#ffffff",
      "buttonIconColor": "#333333",
      "buttonTextColor": "#333333",
      "buttonSelectedColor": "#454545",
      "buttonActionDetails": "451"
    });
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinner = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
        this.buttonPresets = JSON.parse(response['metadata']);
        this.buttonPreset = this.buttonPresets[this.buttonPresetID];
        this.button = this.buttonPreset.buttons[0];
        this.buttonID = 0;
        this.createButtonForm();
        this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
        this.router.navigate(['/settings'],{});
        this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  handleDeleteButton(){
    this.showSpinner = true;
    let currentButtons = this.buttonPresets[this.buttonPresetID].buttons;
    const index = this.buttonPresets[this.buttonPresetID].buttons.indexOf(this.button);
    if (index > -1) {
      this.buttonPresets[this.buttonPresetID].buttons.splice(index, 1);
    }
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinner = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
        this.buttonPresets = JSON.parse(response['metadata']);
        this.buttonPreset = this.buttonPresets[this.buttonPresetID];
        this.button = this.buttonPreset.buttons[0];
        this.buttonID = 0;
        this.createButtonForm();
        this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
        this.router.navigate(['/settings'],{});
        this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  handleActiveComp(compIndex){
    this.comp = this.componentsList[compIndex];
    this.compID = this.comp.id;
    this.createForm();
  }

  handleActiveButtonPreset(buttonPresetIndex){
    this.buttonPreset = this.buttonPresets[buttonPresetIndex];
    this.buttonPresetID = buttonPresetIndex;
    this.button = this.buttonPreset.buttons[0];
    this.buttonID = 0;
    this.createButtonForm();
  }

  handleActiveButton(buttonIndex){
    this.button = this.buttonPreset.buttons[buttonIndex];
    this.buttonID = buttonIndex;
    this.createButtonForm();
  }

  saveFrameSettings(form: any){
    let formValue = form;
    let metadata:any = {};
    let customEvents: any = {};
    this.eventTypes.forEach(element => {
      if(formValue[element.id] && formValue[element.id].length === 0){
        customEvents[element.id] = null;
      }else{
        customEvents[element.id] = formValue[element.id];
      }
      delete formValue[element.id];
    });

    metadata['appendComponent'] = {'type': 'action', 'userRole': formValue.userRole};
    delete formValue['userRole'];
    for (let [key, value] of Object.entries(formValue)) {
      if(key !== 'frameBGColor' && key !== 'componentsHFontSize' && key !== 'componentsHFontFamily' && key !== 'componentsHFontAlignment' && key !== 'componentsBFontSize' && key !== 'componentsBFontFamily' && key !== 'componentsBFontAlignment'){
        metadata[key] = formValue[key];
      }
    }
    metadata.customEvents = customEvents;
    metadata.customCss = JSON.parse(formValue.customCss);
    metadata.customCss['background-color'] = formValue.frameBGColor;
    metadata.customCss.componentHeader = {};
    metadata.customCss.componentHeader['font-size'] = formValue.componentsHFontSize;
    metadata.customCss.componentHeader['font-family'] = formValue.componentsHFontFamily;
    metadata.customCss.componentHeader['text-align'] = formValue.componentsHFontAlignment;
    metadata.customCss.componentHeader['line-height'] = formValue.componentsHLineHeight;
    metadata.customCss.componentHeader['letter-spacing'] = formValue.componentsHLetterSpacing;
    metadata.customCss.componentBody = {};
    metadata.customCss.componentBody['font-size'] = formValue.componentsBFontSize;
    metadata.customCss.componentBody['font-family'] = formValue.componentsBFontFamily;
    metadata.customCss.componentBody['text-align'] = formValue.componentsBFontAlignment;
    metadata.customCss.componentBody['line-height'] = formValue.componentsBLineHeight;
    metadata.customCss.componentBody['letter-spacing'] = formValue.componentsBLetterSpacing;
    metadata = JSON.stringify(metadata);
    //console.log(metadata);return;
    this.showSpinner = true;
    this.settingsService.updateSettingsByType('frame', metadata).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The frame settings are updated successfully!', { keepAfterRouteChange: true });
          this.router.navigate(['/settings'],{ /*queryParams: {id: response.id, action: 'edit', stationId: this.stationId}*/});
          this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

}
