import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

import { Frame } from 'src/app/objects/frame';
import { AlertService } from 'src/app/services/alert.service';
import { FrameService } from 'src/app/services/frame.service';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.css']
})
export class BrowseComponent implements OnInit {

  public frames: any;
  public showSpinner: boolean = true;
  public stationId: Number = null;

  searchForm: FormGroup = null;
  searchText : String = null;

  constructor(
    public frameService: FrameService, 
    private route: ActivatedRoute,
    private alertService: AlertService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      this.stationId = params['stationId'];
      this.searchText = params['search'];
      this.getAllFrames();
    });
  }

  ngOnInit() {
    this.getAllFrames();
    this.searchForm = this.fb.group({
      text: [this.searchText, Validators.required],
    });
  }

  getAllFrames(){
    this.showSpinner = true;
    this.frameService.getAllFrames().subscribe((res: any) => {
      if(this.searchText){
        this.frames = res.filter((item: Frame) => {
          return item.description.toLowerCase().includes(this.searchText.toLowerCase()) || ((item.id).toString() == this.searchText);
        });
      }else{
        this.frames = res;
      }
      this.showSpinner = false;
    });
  }

  handleDelete(frameID: string){
    this.showSpinner = true;
    this.frameService.deleteFrame(frameID).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The frame is successfully deleted!', { keepAfterRouteChange: true });
          this.getAllFrames();
          this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  handleAddFrom(frameID: string){
    this.showSpinner = true;
    this.frameService.copyFrameById(frameID).pipe(first()).subscribe({
      next: (response:any) => {
        this.getAllFrames();
        this.alertService.success('New frame is added from given frame ID!', { keepAfterRouteChange: true });
        this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  handleSearch(){
    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: { 'search': this.searchForm.value.text },
        queryParamsHandling: 'merge'
      }
    );
  }

}
