import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ListsService } from 'src/app/objects/lists';
import { AlertService } from 'src/app/services/alert.service';
import { FrameService } from 'src/app/services/frame.service';

@Component({
  selector: 'app-frame-associations',
  templateUrl: './frame-associations.component.html',
  styleUrls: ['./frame-associations.component.css']
})
export class FrameAssociationsComponent implements OnInit {

  public action: string = 'list';
  public frameID: string = null;
  public stationId: string = null;
  public assocId: string = null;
  showSpinner: boolean = true;
  showEditorLink: boolean = false;
  errorMessage: string = null;
  public frameApps: any = null;
  public frameAssoc: any = null;
  frameForm: FormGroup = null;
  public sourceTypes: any;
  public conflictFrameID: any = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public frameService: FrameService,
    private fb: FormBuilder,
    private lists: ListsService,
    private alertService: AlertService) { 
    this.route.queryParams.subscribe(params => {
      this.action = params['action'];
      this.frameID = (params['id']) ? params['id'] : null;
      this.stationId = (params['stationId']) ? params['stationId'] : null;
      this.assocId = (params['assocId']) ? params['assocId'] : null;
      this.updateContent();
      this.showSpinner = false;
    });
  }

  ngOnChanges(): void {
  }

  ngOnInit() {
    this.updateContent();
  }

  updateContent(){
    this.showSpinner = true;
    this.errorMessage = null;
    if(this.action === 'list'){
      this.frameService.getFrameApps(this.frameID).subscribe((res: any) => {
        if(res && res.length){
          console.log(res);
          this.frameApps = res;
          this.showSpinner = false;
        }else{
          this.frameApps = null;
          this.showSpinner = false;
        }
      });
    }else if(this.action === 'edit'){
      this.lists.getSourceTypes().subscribe((items: any) => {
        this.sourceTypes = items;
      });
      this.frameService.getFrameAppById(this.assocId).subscribe((res: any) => {
        if(res){
          console.log(res);
          this.frameAssoc = res;
          this.showEditorLink = true;
          this.createForm();
          this.showSpinner = false;
        }else{
          this.frameAssoc = null;
          this.showSpinner = false;
        }
      });
    }else if(this.action === 'new'){
      this.lists.getSourceTypes().subscribe((items: any) => {
        this.sourceTypes = items;
        this.createForm();
        this.showSpinner = false;
      });
    }
  }

  createForm() {
    if(this.action === 'edit'){
      this.frameForm = this.fb.group({
        sourceId: [this.frameAssoc['sourceId']],
        sourceType: [this.frameAssoc['sourceType']],
        lang: [this.frameAssoc['lang']],
      });
    }else{
      this.frameForm = this.fb.group({
        sourceId: [0],
        sourceType: [null],
        lang: ['en'],
      });
    }
  }

  newFramApp(formValue: any){
    let newFrameApp:any = {};
    newFrameApp.lang = formValue.lang;
    newFrameApp.sourceId = formValue.sourceId;
    newFrameApp.sourceType = formValue.sourceType;

    this.showSpinner = true;
    this.frameService.addFrameApp(this.frameID, newFrameApp).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The Frame App is added successfully!', { keepAfterRouteChange: true });
          //this.router.navigate(['/associations'],{ queryParams: {id: this.frameID, action: 'list'}});
          this.showEditorLink = true;
          this.errorMessage = null;
          this.showSpinner = false;
      },
      error: error => {
        this.errorMessage = error.message + ' ';
        this.conflictFrameID = error.frameId;
        //this.alertService.error(error, { autoClose: false, keepAfterRouteChange: true });
        this.showSpinner = false;
      }
    });
  }

  handleRoute(){
    this.router.navigate(['/associations'],{ queryParams: {id: this.conflictFrameID, action: 'list'}});
  }

  saveForm(formValue: any){
    let updatedFrame:any = {};
    updatedFrame.lang = formValue.lang;
    updatedFrame.sourceId = formValue.sourceId;
    updatedFrame.sourceType = formValue.sourceType;

    this.showSpinner = true;
    this.frameService.updateFrameApp(updatedFrame, this.assocId).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The Frame App is Updated Successfully!', { keepAfterRouteChange: true });
          this.errorMessage = null;
          this.showEditorLink = true;
          this.updateContent();
          this.showSpinner = false;
      },
      error: error => {
        this.errorMessage = error.message + ' ';
        this.conflictFrameID = error.frameId;
        //this.alertService.error(error, { autoClose: false, keepAfterRouteChange: true });
        this.showSpinner = false;
      }
    });
  }

  handleDelete(assocId: string){
    this.showSpinner = true;
    this.frameService.deleteFrameApp(assocId).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The frame app is successfully deleted!', { keepAfterRouteChange: true });
          this.updateContent();
          this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

}
