import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, first } from 'rxjs/operators';
import { AuthService } from 'src/app/services';
import { AlertService } from 'src/app/services/alert.service';

enum EmailStatus {
    Verifying,
    Failed
}

//reset password:
enum TokenStatus {
    Validating,
    Valid,
    Invalid
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    form: FormGroup = null;
    registerForm: FormGroup = null;
    fpForm: FormGroup = null;
    action: string;
    loading = false;
    submitted = false;

    EmailStatus = EmailStatus;
    emailStatus = EmailStatus.Verifying;
    emailError = null;

    //reset password:
    TokenStatus = TokenStatus;
    tokenStatus = TokenStatus.Validating;
    token = null;
    rpForm: FormGroup = null;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AuthService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.action = params['action'] ? params['action'] : 'login';
            if(this.action === 'register'){
                this.registerForm = this.formBuilder.group({
                    title: ['', Validators.required],
                    firstName: ['', Validators.required],
                    lastName: ['', Validators.required],
                    email: ['', [Validators.required, Validators.email]],
                    password: ['', Validators.required],
                    confirmPassword: ['', Validators.required],
                    acceptTerms: [true, Validators.required],
                });
            }else if(this.action === 'login'){
                this.form = this.formBuilder.group({
                    email: ['', [Validators.required, Validators.email]],
                    password: ['', Validators.required]
                });
            }else if(this.action === 'forgotPassword'){
                this.fpForm = this.formBuilder.group({
                    email: ['', [Validators.required, Validators.email]]
                });
            }else if(this.action === 'resetPassword'){
                this.rpForm = this.formBuilder.group({
                    password: ['', [Validators.required, Validators.minLength(6)]],
                    confirmPassword: ['', Validators.required],
                });
                const token = this.route.snapshot.queryParams['token'];
                // remove token from url to prevent http referer leakage
                //this.router.navigate([], { relativeTo: this.route, replaceUrl: true });
                this.accountService.validateResetToken(token)
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.token = token;
                        this.tokenStatus = TokenStatus.Valid;
                    },
                    error: () => {
                        this.tokenStatus = TokenStatus.Invalid;
                    }
                });
            }else if(this.action === 'verifyEmail'){
                this.loading = true;
                const token = this.route.snapshot.queryParams['token'];
                // remove token from url to prevent http referer leakage
                this.router.navigate([], { relativeTo: this.route, replaceUrl: true });
                this.accountService.verifyEmail(token)
                .pipe(first())
                .subscribe({
                    next: () => {
                        this.alertService.success('Verification successful, you can now login', { keepAfterRouteChange: true });
                        this.router.navigate(['/login']);
                        this.loading = false;
                    },
                    error: error => {
                        this.emailError = error;
                        this.emailStatus = EmailStatus.Failed;
                        this.loading = false;
                    }
                });
            }
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    get rf() { return this.registerForm.controls; }
    get fp() { return this.fpForm.controls; }
    // convenience getter for easy access to form fields
    get rp() { return this.rpForm.controls; }

    saveForm() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        this.loading = true;
        this.accountService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    // get return url from query parameters or default to home page
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/browse';
                    this.router.navigateByUrl(returnUrl);
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    saveRegisterForm() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();
        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        this.loading = true;
        this.accountService.register(this.registerForm.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.loading = false;
                    // get return url from query parameters or default to home page
                    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/login';
                    this.router.navigateByUrl(returnUrl);
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    saveFpForm() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();
        // stop here if form is invalid
        if (this.fpForm.invalid) {
            return;
        }
        this.loading = true;
        this.alertService.clear();
        this.accountService.forgotPassword(this.fp.email.value)
            .pipe(first())
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: () => this.alertService.success('Please check your email for password reset instructions'),
                error: error => this.alertService.error(error)
            });
    }

    saveRpForm() {
        this.submitted = true;
        // reset alerts on submit
        this.alertService.clear();
        // stop here if form is invalid
        if (this.rpForm.invalid) {
            return;
        }
        this.loading = true;
        this.accountService.resetPassword(this.token, this.rp.password.value, this.rp.confirmPassword.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success('Password reset successful, you can now login', { keepAfterRouteChange: true });
                    this.router.navigateByUrl('/login');
                    this.loading = false;
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }
}
