import { Component, OnInit, Input } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ComponentsService } from 'src/app/services/components.service';

import { ComponentObj } from 'src/app/objects/componentObj';
import { Page } from 'src/app/objects/page';
import { ListsService } from 'src/app/objects/lists';
import { SettingsService } from 'src/app/services/settings.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { PagesService } from 'src/app/services/pages.service';
import { first } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-components-editor',
  templateUrl: './components-editor.component.html',
  styleUrls: ['./components-editor.component.css']
})
export class ComponentsEditorComponent implements OnInit {

  @Input() page: any;
  @Input() framePages: any;
  @Input() comp: any;
  @Input() pageComponents: any;
  @Input() icons: any;

  pageValue: Page = null;
  framePagesValue: [] = null;
  compValue: any = null;
  pageComponentsValue: any = null;

  compForm: FormGroup = null;
  compMetaForm: FormGroup = null;
  newCompForm: FormGroup = null;

  errorMessage: string = '';
  showSpinner: boolean = true;
  public events: any;
  public actions: any;
  public bindingFieldsList: any;
  public bindingFields12List: any;

  public compID: any = null;
  public pageID: any = null;
  public compAction: string = 'edit';
  public componentsList: [] = null;

  compSettings: any = null;
  formFields: any = null;

  public stationId: any = null;
  public frameId: any = null;
  public currentGroup = '';

  public customEvents: any = null;

  constructor(
    public ComponentsService: ComponentsService, 
    public lists: ListsService, 
    private fb: FormBuilder, 
    public componentsService: ComponentsService, 
    public pagesService: PagesService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router) { 
      this.route.queryParams.subscribe(params => {
        this.stationId = params['stationId'];
        this.frameId = params['id'];
      });
  }

  ngOnChanges(): void {
    if(this.framePages){
      this.framePages.subscribe((x) => {
        this.framePagesValue = x;
      });
    }

    if(this.page){
      this.page.subscribe((x) => {
        if(x){
          this.pageValue = x;
          this.pageID = this.pageValue['pageId'];
          this.getPageComponents(this.pageID);
          this.showSpinner = false;
        }else{
          this.pageValue = null;
          this.pageID = null;
          this.pageComponentsValue = null;
          this.compValue = null;
          this.showSpinner = false;
        }
      });
    }

    if(this.pageComponents){
      this.pageComponents.subscribe((x) => {
        if(x && x.length){
          let pageComponents = x;
          pageComponents.forEach((element: any) => {
            if('stationcard_component' in element){
              element.name = element['stationcard_component'].name;
              element.description = element['stationcard_component'].description;
              element.type = element['stationcard_component'].type;
            }
          });
          this.pageComponentsValue = pageComponents;
          //console.log(this.pageComponentsValue);
        }else{
          this.pageComponentsValue = null;
        }
      });
    }

    if(this.comp){
      this.comp.subscribe((x) => {
        if(x){
          this.customEvents = null;
          this.compValue = x;
          this.compID = this.compValue['id'];
          if('stationcard_component' in this.compValue){
            this.compValue['name'] = this.compValue['stationcard_component']['name'];
            this.compValue['description'] = this.compValue['stationcard_component']['description'];
          }
          if(this.compValue['type'] === ('timer') || this.compValue['type'] === ('voiceEmbed') || this.compValue['type'] === ('placeholder') || this.compValue['type'] === ('missionInteractive')){
            if(('customEvents' in this.compValue) && this.compValue['customEvents']){
              this.customEvents = JSON.parse(this.compValue['customEvents']).events;
            }else{
              this.customEvents = null;
            }
          }
          this.getComponentSettings();
          this.createForm('compForm');
          if(!this.pageComponentsValue || !this.pageComponentsValue.length){
            this.compAction = 'delete';
          }else{
            this.compAction = 'edit';
          }
        }
        this.showSpinner = false;
      });
    }

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pageComponentsValue, event.previousIndex, event.currentIndex);
    this.showSpinner = true;
    this.pagesService.updateComponentsSortOrder(this.pageComponentsValue).pipe(first()).subscribe({
      next: (response: any) => {
        this.alertService.success('The components sort order is successfully updated!', { keepAfterRouteChange: true });
        if(this.pageComponents){
          this.pageComponents.next(response);
          if(response.length){
            if(this.comp){
              this.comp.next(response[0]);
            }else{
              this.comp = new BehaviorSubject<ComponentObj>(response[0]);
            }
          }else{
            if(this.comp){
              this.comp.next(null);
            }else{
              this.comp = new BehaviorSubject<ComponentObj>(null);
            }
          }
        }else{
          this.pageComponents = new BehaviorSubject<ComponentObj[]>(response);
        }
        const queryParams: Params = { activeTab: 3 };
        this.router.navigate(
          [], 
          {
            relativeTo: this.route,
            queryParams: queryParams, 
            queryParamsHandling: 'merge',
          }
        );
        this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  ngOnInit() {
    this.settingsService.getSettingsByType('events_list').subscribe((res: any) => {
      if(res){
        this.events = JSON.parse(res['metadata']);
      }
    });

    this.lists.getActionsList().subscribe((actions: any) => {
      this.actions = actions;
    });

    this.lists.getBindingFieldsList().subscribe((items: any) => {
      this.bindingFieldsList = items;
    });

    this.lists.getBindingFields12List().subscribe((items: any) => {
      this.bindingFields12List = items;
    });
  }

  getComponentSettings(){
    this.settingsService.getSettingsByType('meta_fields').subscribe((res: any) => {
      if(res){
        this.compSettings = JSON.parse(res['metadata']);
        this.createForm('compMetaForm');
      }
    });
  }

  addNewCustomField(){
    this.customEvents.push({eventToListen: null, action: null});
  }

  saveCustomEventValue(e, index, type){
    if(type === 'eventToListen'){
      this.customEvents[index]['eventToListen'] = e;
    }else if(type === 'action'){
      this.customEvents[index]['action'] = e;
    }
  }

  deleteCustomEventValue(i){
    if (i > -1) { // only splice array when item is found
      this.customEvents.splice(i, 1); // 2nd parameter means remove one item only
    }
  }

  createForm(formType: string = 'newCompForm') {
    if(formType === 'newCompForm' && this.compValue !== null){
      this.newCompForm = this.fb.group({
        id: [{value: this.compValue.id, disabled: true}, Validators.required],
        name: [{value: this.compValue['name'], disabled: true}, Validators.required],
        description: [{value: this.compValue['description'], disabled: true}],
        sortOrder: [(this.pageComponentsValue && this.pageComponentsValue.length) ? this.pageComponentsValue.length+1: 1, Validators.required],
        eventToListenToShow: [null],
        eventToListenToHide: [null],
        eventToListenToEnable: [null],
        eventToListenToDisable: [null]
      });
      if(this.compValue['type'] === ('timer') || this.compValue['type'] === ('voiceEmbed') || this.compValue['type'] === ('placeholder') || this.compValue['type'] === ('missionInteractive')){
        if(('customEvents' in this.compValue) && this.compValue['customEvents']){
          this.customEvents = JSON.parse(this.compValue['customEvents']).events;
        }else{
          this.customEvents = null;
        }
      }
    }else if(formType === 'compForm'  && this.compValue !== null){
      this.compForm = this.fb.group({
        id: [{value: this.compValue.id, disabled: true}, Validators.required],
        name: [{value: this.compValue['name'], disabled: true}, Validators.required],
        description: [{value: this.compValue['description'], disabled: true}],
        sortOrder: [{value: (this.compValue.sortOrder) ? this.compValue.sortOrder : (this.pageComponentsValue.length+1), disabled: true}, Validators.required],
        eventToListenToShow: [this.compValue.eventToListenToShow],
        eventToListenToHide: [this.compValue.eventToListenToHide],
        eventToListenToEnable: [this.compValue.eventToListenToEnable],
        eventToListenToDisable: [this.compValue.eventToListenToDisable]
      });
      if(this.compValue['type'] === ('timer') || this.compValue['type'] === ('voiceEmbed') || this.compValue['type'] === ('placeholder') || this.compValue['type'] === ('missionInteractive')){
        if(('customEvents' in this.compValue) && this.compValue['customEvents']){
          this.customEvents = JSON.parse(this.compValue['customEvents']).events;
        }else{
          this.customEvents = null;
        }
      }
    }else if(formType === 'compMetaForm'  && this.compValue !== null){
      this.compMetaForm = null;
      let formGroup: any = {};
      let formFields: any = [];
      if(this.compValue.metadata){
        let compMeta = JSON.parse(this.compValue.metadata);
        //console.log(compMeta);
        if(compMeta){
          for (let [key, value] of Object.entries(compMeta)) {
            let entry = this.compSettings[key];
            if('hasChild' in entry){
              if('isComponent' in entry){
                let innerObj = compMeta[key];
                for (let [key1, value1] of Object.entries(innerObj)) {
                  if(typeof innerObj[key1] === 'object'){
                    let innerInnerObj = innerObj[key1];
                    for (let [key2, value2] of Object.entries(innerInnerObj)) {
                      if(key2 !== 'hasChild' && (key2 in innerInnerObj)){
                        let field: any = {};
                        if(key in this.compSettings && typeof innerObj[key1] !== 'object'){
                          entry = this.compSettings[key];
                          formGroup[key1] = new FormControl(innerObj[key1]);
                          field['formControl'] = key1;
                          field['label'] = entry[key1].label;
                          field['type'] = entry[key1].type;
                          field['value'] = innerObj[key1];
                          if(entry[key1].options){
                            field['options'] = entry[key1].options;
                          }
                          if(key !== this.currentGroup){
                            this.currentGroup = key;
                            field['groupTitle'] = key;
                          }
                          field['group'] = key;
                          formFields.push(field);
                        }
                      }
                    }
                  }else{
                    let field: any = {};
                    entry = this.compSettings[key1];
                    formGroup[key+'.'+key1] = new FormControl(compMeta[key][key1]);
                    field['formControl'] = key+'.'+key1;
                    field['label'] = entry.label;
                    field['type'] = entry.type;
                    field['value'] = compMeta[key][key1];
                    if(entry.options){
                      field['options'] = entry.options;
                    }
                    if(key !== this.currentGroup){
                      this.currentGroup = key;
                      field['groupTitle'] = key;
                    }
                    field['group'] = key;
                    formFields.push(field);
                  }
                }
              }else{
                let innerObj = compMeta[key];
                for (let [key1, value1] of Object.entries(innerObj)) {
                  if(key1 !== 'hasChild' && (key1 in innerObj) && typeof compMeta[key] === 'object'){
                    let field: any = {};
                    if(key in this.compSettings && typeof innerObj[key1] !== 'object'){
                      entry = this.compSettings[key];
                      formGroup[key1] = new FormControl(innerObj[key1]);
                      field['formControl'] = key1;
                      field['label'] = entry[key1].label;
                      field['type'] = entry[key1].type;
                      field['value'] = innerObj[key1];
                      if(entry[key1].options){
                        field['options'] = entry[key1].options;
                      }
                      field['group'] = key;
                      formFields.push(field);
                    }
                  }
                }
              }
            }else{
              let field: any = {};
              formGroup[key] = new FormControl(compMeta[key]);
              //console.log(entry);
              field['formControl'] = key;
              field['label'] = entry.label;
              field['type'] = entry.type;
              field['value'] = compMeta[key];
              if(entry.options){
                field['options'] = entry.options;
              }
              formFields.push(field);
            }
          }
          this.formFields = formFields;
          this.compMetaForm = this.fb.group(formGroup);
        }else{
          this.compMetaForm = null;
        }
      }
      
    }
  }

  saveForm(formValue: any){
    let metadata: any = {};
    let updatedComponent: any = {};
    updatedComponent.eventToListenToShow = formValue.eventToListenToShow;
    updatedComponent.eventToListenToHide = formValue.eventToListenToHide;
    updatedComponent.eventToListenToEnable = formValue.eventToListenToEnable;
    updatedComponent.eventToListenToDisable = formValue.eventToListenToDisable;

    let compMetaFormValue = this.compMetaForm.value;
    let compMeta = JSON.parse(this.compValue.metadata);
    for (let [key, value] of Object.entries(compMeta)) {
      let innerObj = compMeta[key];
      if(innerObj && typeof innerObj === 'object' && !(innerObj instanceof Array)){
        let innerInnerObj = {};
        for (let [key1, value1] of Object.entries(innerObj)) {
          if((key+'.'+key1) in compMetaFormValue){
            innerInnerObj[key1] = compMetaFormValue[key+'.'+key1];
          }else{
            innerInnerObj[key1] = compMetaFormValue[key1];
          }
        }
        metadata[key] = innerInnerObj;
      }else{
        metadata[key] = compMetaFormValue[key];
      }
    }

    updatedComponent.metadata = JSON.stringify(metadata);
    updatedComponent.customEvents = (this.customEvents && this.customEvents.length) ? JSON.stringify({"events": this.customEvents}) : JSON.stringify({"events": null});
    this.showSpinner = true;
    //console.log(updatedComponent);return;
    this.pagesService.updatePageComponent(this.pageID, this.compID, updatedComponent).pipe(first()).subscribe({
      next: (response: any) => {
        this.alertService.success('The component is successfully updated!', { keepAfterRouteChange: true });
        if(this.pageComponents){
          this.pageComponents.next(response);
          if(response.length){
            if(this.comp){
              this.comp.next(response[0]);
            }else{
              this.comp = new BehaviorSubject<ComponentObj>(response[0]);
            }
          }else{
            if(this.comp){
              this.comp.next(null);
            }else{
              this.comp = new BehaviorSubject<ComponentObj>(null);
            }
          }
        }else{
          this.pageComponents = new BehaviorSubject<ComponentObj[]>(response);
        }
        const queryParams: Params = { activeTab: 3 };
        this.router.navigate(
          [], 
          {
            relativeTo: this.route,
            queryParams: queryParams, 
            queryParamsHandling: 'merge',
          }
        );
        this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  handleDelete(){
    this.showSpinner = true;
    this.pagesService.deletePageComponent(this.pageID, this.compID).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The component is successfully deleted!', { keepAfterRouteChange: true });
          if(this.pageComponents){
            this.pageComponents.next(response);
            if(response.length){
              if(this.comp){
                this.comp.next(response[0]);
              }else{
                this.comp = new BehaviorSubject<ComponentObj>(response[0]);
              }
            }else{
              if(this.comp){
                this.comp.next(null);
              }else{
                this.comp = new BehaviorSubject<ComponentObj>(null);
              }
            }
          }else{
            this.pageComponents = new BehaviorSubject<ComponentObj[]>(response);
          }
          const queryParams: Params = { activeTab: 3 };
          this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: queryParams, 
              queryParamsHandling: 'merge',
            }
          );
          if(!this.pageComponentsValue || !this.pageComponentsValue.length){
            this.compAction = 'delete';
          }else{
            this.compAction = 'edit';
          }
          this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

  handleActiveComp(compIndex){
    this.showSpinner = true;
    this.compAction = 'edit';
    if(this.pageComponentsValue[compIndex]){
      this.comp.next(this.pageComponentsValue[compIndex]);
      this.compValue = this.comp.value;
      this.compID = this.compValue.id;
      this.getComponentSettings();
      this.createForm('compForm');
      this.showSpinner = false;
    }
  }

  handleActivePage(pageIndex){
    this.showSpinner = true;
    this.page.next(this.framePagesValue[pageIndex]);
    this.pageValue = this.page.value;
    this.pageID = this.pageValue['pageId'];
    this.getComponentSettings();
    this.createForm('compForm');
    this.showSpinner = false;
  }

  getPageComponents(pageID: number){
    this.showSpinner = true;
    this.pagesService.getPageComponents(pageID).subscribe((res: any) => {
      if(res && res.length){
        this.pageComponents.next(res);
        this.pageComponentsValue = this.pageComponents.value;
        this.pageComponentsValue.forEach((element: any) => {
          if('stationcard_component' in element){
            element.name = element['stationcard_component'].name;
            element.description = element['stationcard_component'].description;
          }
        });
        if(this.pageComponentsValue.length){
          this.compValue = this.pageComponentsValue[0];
          this.compID = this.compValue.id;
          this.comp.next(this.compValue);
        }else{
          this.compValue = null;
          this.compID = null;
        }
      }else{
        this.pageComponentsValue = null;
        this.compValue = null;
        this.compID = null;
      }
      this.showSpinner = false;
    });
  }

  handleAdd(){
    this.showSpinner = true;
    this.compAction = 'select';
    this.componentsService.getAllComponents().subscribe((x: any) => {
      this.componentsList = x;
      this.compID = 0;
      this.showSpinner = false;
    });
  }

  handleAddComp(compID){
    this.showSpinner = true;
    this.compAction = 'add';
    this.componentsService.getComponentById(compID).subscribe((x: any) => {
      if(x){
        this.compValue = x;
        this.compID = x.id;
        this.getComponentSettings();
        this.createForm('newCompForm');
        this.showSpinner = false;
      }else{
        this.compValue = null;
        this.compID = null;
        this.showSpinner = false;
      }
    });
  }

  addForm(formValue: any){
    let metadata: any = {};
    let newComponent: any = {};
    newComponent.pageId = this.pageID;
    newComponent.componentId = this.compID;
    newComponent.sortOrder = formValue.sortOrder;
    newComponent.eventToListenToShow = formValue.eventToListenToShow;
    newComponent.eventToListenToHide = formValue.eventToListenToHide;
    newComponent.eventToListenToEnable = formValue.eventToListenToEnable;
    newComponent.eventToListenToDisable = formValue.eventToListenToDisable;
    
    let compMetaFormValue = this.compMetaForm.value;
    let compMeta = JSON.parse(this.compValue.metadata);
    for (let [key, value] of Object.entries(compMeta)) {
      let innerObj = compMeta[key];
      if(innerObj && (typeof innerObj === 'object') && !(innerObj instanceof Array)){
        let innerInnerObj = {};
        for (let [key1, value1] of Object.entries(innerObj)) {
          if((key+'.'+key1) in compMetaFormValue){
            innerInnerObj[key1] = compMetaFormValue[key+'.'+key1];
          }else{
            innerInnerObj[key1] = compMetaFormValue[key1];
          }
        }
        metadata[key] = innerInnerObj;
      }else{
        metadata[key] = compMetaFormValue[key];
      }
    }

    newComponent.metadata = JSON.stringify(metadata);
    newComponent.customEvents = (this.customEvents && this.customEvents.length) ? JSON.stringify({"events": this.customEvents}) : JSON.stringify({"events": null});
    this.showSpinner = true;
    this.pagesService.addPageComponent(this.pageID, newComponent).pipe(first()).subscribe({
      next: (response: any) => {
        this.alertService.success('New component is successfully added!', { keepAfterRouteChange: true });
        if(response && response.length){
          this.pageComponents.next(response);
          if(this.comp){
            this.comp.next(response[0]);
          }
        }
        this.compAction = 'edit';
        const queryParams: Params = { activeTab: 3 };
        this.router.navigate(
          [], 
          {
            relativeTo: this.route,
            queryParams: queryParams, 
            queryParamsHandling: 'merge',
          }
        );
        this.showSpinner = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinner = false;
      }
    });
  }

}
