import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComponentObj } from '../objects/componentObj';
import { Page } from '../objects/page';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  public emptyPage = {
    id: 0,
    title: null,
    description: null,
    type: "do",
    metadata: null,
    isDisplayFirst: false,
    triggerEvent1: null,
    triggerEvent2: null,
    eventToListenToShow: null,
    eventToListenToHide: null,
    eventToListenToEnable: null,
    eventToListenToDisable: null,
    customEvents: null,
    eventToListen: null,
    action: null,
  };

  baseURL = environment.apiURL + '/pages';

  constructor(
    private http: HttpClient
  ) { }

  getEmptyPage(){
    return of(this.emptyPage);
  }

  getPageById(id){
    return this.http.get<Page>(`${this.baseURL}/${id}`,{ withCredentials: true });
  }

  getPageComponents(id){
    return this.http.get<ComponentObj[]>(`${this.baseURL}/${id}/components`,{ withCredentials: true });
  }

  addPage(page: any){
    return this.http.post(`${this.baseURL}/`, 
    {
      description: page.description, 
      type: page.type,
      metadata: page.metadata
    }, { withCredentials: true }); 
  }

  addPageComponent(pageId: string, component: any){
    return this.http.post(`${this.baseURL}/${pageId}/component`, 
    {
      pageId: parseInt(pageId),
      componentId: parseInt(component.componentId), 
      sortOrder: parseInt(component.sortOrder), 
      eventToListenToShow: component.eventToListenToShow,
      eventToListenToHide: component.eventToListenToHide,
      eventToListenToEnable: component.eventToListenToEnable,
      eventToListenToDisable: component.eventToListenToDisable,
      metadata: component.metadata, 
      customEvents: component.customEvents
    }, { withCredentials: true }); 
  }

  updatePageComponent(pageId: string, componentId: string, component: any){
    return this.http.put(`${this.baseURL}/${pageId}/component/${componentId}`, 
    {
      eventToListenToShow: component.eventToListenToShow,
      eventToListenToHide: component.eventToListenToHide,
      eventToListenToEnable: component.eventToListenToEnable,
      eventToListenToDisable: component.eventToListenToDisable,
      metadata: component.metadata, 
      customEvents: component.customEvents
    }, { withCredentials: true }); 
  }

  updateComponentsSortOrder(components: any){
    return this.http.post(`${this.baseURL}/sortorder/`, 
    {
      components: components
    }, { withCredentials: true }); 
  }

  deletePageComponent(pageId: string, componentId: string){
    return this.http.delete(`${this.baseURL}/${pageId}/component/${componentId}`, { withCredentials: true }); 
  }

}
