import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { BrowseComponent } from './components/browse/browse.component';
import { EditorComponent } from './components/editor/editor.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AuthGuard } from './helpers';
import { Role } from './objects/role';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { FrameAssociationsComponent } from './components/frame-associations/frame-associations.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'browse', component: BrowseComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: 'editor', component: EditorComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: 'associations', component: FrameAssociationsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: '', component: BrowseComponent, pathMatch: 'full', canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
  { path: '**', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }