import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ComponentObj } from 'src/app/objects/componentObj';
import { Frame } from 'src/app/objects/frame';
import { Page } from 'src/app/objects/page';
import { FrameService } from 'src/app/services/frame.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-previewer',
  templateUrl: './mobile-previewer.component.html',
  styleUrls: ['./mobile-previewer.component.css']
})
export class MobilePreviewerComponent implements OnInit {

  @Input() frame: any;
  @Input() button: any;
  @Input() frameButtons: any;
  @Input() page: any;
  @Input() comp: ComponentObj;
  @Input() pageComponents: any;

  frameValue: any = null;
  frameButtonsValue: any = null;
  buttonValue: any = null;
  pageValue: any = null;
  compValue: any = null;
  pageComponentsValue: any = null;

  public previewerClasses: string;
  public activeButton: number;
  public topLeftButton: any = null;
  public topRightButton: any = null;
  public bottomLeftButton: any = null;
  public bottomRightButton: any = null;
  public frameStyles: any = {};
  public buttonTopLeftStyle: any = {};
  public buttonTopRightStyle: any = {};
  public buttonBottomLeftStyle: any = {};
  public buttonBottomRightStyle: any = {};
  public buttonsStyle: any = {};
  public componentStyles: any = {};

  public showSpinner = true;
  public action: string;
  public stationId: Number = null;
  public frameId: any = null;

  public iFrameURL: string = null;
  public refreshIFrame: boolean = false;

  public contents: any = {
    'ready': false,
    'richinfo': {'developerLearning': ''},
    'embed': {'arrVisualDescription': ''}
  };
  //public contentReady: boolean = false;
  public isProduction: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, public frameService: FrameService) { 
    this.isProduction = environment.production;
    this.route.queryParams.subscribe(params => {
      this.action = params['action'];
      this.stationId = params['stationId'];
      this.frameId = params['id'];
      this.iFrameURL = environment.previewURL + this.stationId + '&frameId=' + this.frameId;
      this.refreshIframe();
      if(this.stationId && this.isProduction){
        this.getStationData();
      }
    });
  }

  ngOnChanges(): void {
    if(this.frame){
      this.frame.subscribe((x) => {
        this.previewerClasses  = 'previewer-content ' + x.langType + ' ';
        this.previewerClasses  += x.style + ' ';
        this.frameStyles['backgroundColor'] = x.frameBGColor;

        this.buttonTopLeftStyle['backgroundColor'] = x.buttonTopLeftStyle;
        this.buttonTopRightStyle['backgroundColor'] = x.buttonTopRightStyle;
        this.buttonBottomLeftStyle['backgroundColor'] = x.buttonBottomLeftStyle;
        this.buttonBottomRightStyle['backgroundColor'] = x.buttonBottomRightStyle;

        this.buttonTopLeftStyle['opacity'] = x.buttonsOpacity;
        this.buttonTopRightStyle['opacity'] = x.buttonsOpacity;
        this.buttonBottomLeftStyle['opacity'] = x.buttonsOpacity;
        this.buttonBottomRightStyle['opacity'] = x.buttonsOpacity;

        this.componentStyles['backgroundColor'] = x.componentsBgColor;
        this.componentStyles['color'] = x.componentsFontColor;

        this.previewerClasses  += x.frameNavType + ' ';
        this.showSpinner = false;
      });
    }

    if(this.frameButtons){
      this.frameButtons.subscribe((x) => {
        if(x){
          this.frameButtonsValue = x;
          this.frameButtonsValue.forEach((item: any) => {
            if(item.position === 'topLeft'){
              this.topLeftButton = item;
            }else if(item.position === 'topRight'){
              this.topRightButton = item;
            }else if(item.position === 'bottomLeft'){
              this.bottomLeftButton = item;
            }else if(item.position === 'bottomRight'){
              this.bottomRightButton = item;
            }
          });
        }else{
          this.frameButtonsValue = null;
          this.topLeftButton = null;
          this.topRightButton = null;
          this.bottomLeftButton = null;
          this.bottomRightButton = null;
        }
      });
    }

    if(this.button){
      this.button.subscribe((x) => {
        this.buttonValue = x;
      });
    }

    if(this.buttonValue){
      this.activeButton = this.buttonValue.buttonId;
      this.showSpinner = false;
    }

    if(this.stationId && this.isProduction){
      this.getStationData();
    }

    if(this.pageComponents){
      this.pageComponents.subscribe((x) => {
        if(x){
          this.pageComponentsValue = x;
          this.pageComponentsValue.forEach((element: any) => {
            element.contents = JSON.stringify(element);
          });
        }else{
          this.pageComponentsValue = null;
        }
      });
    }
  }

  ngOnInit() {}

  getStationData(){
    this.frameService.getStationData(this.stationId).subscribe((x:any) => {
      //console.log(x);
      this.contents.richinfo.developerLearning = x.developerLearning;
      this.contents.embed.arrVisualDescription = x.ArrVisualDescription;
      this.contents.ready = true;
    });
  }

  refreshIframe(){
    this.refreshIFrame = true;
    setTimeout (() => {
      this.refreshIFrame = false;
   }, 50);
  }

}
