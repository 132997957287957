import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ComponentObj } from '../objects/componentObj';

@Injectable({
  providedIn: 'root'
})
export class ComponentsService {

  public emptyComponent = {
    uid: 0,
    name: "richinfo",
    description: "A rich text editor.",
    type: "richinfo",
    sortOrder: 0,
    hideEvent: null,
    showEvent: null,
    enableEvent: null,
    disableEvent: null,
    metadata: {
      "componentTitle": "info default",
      "showComponentTitle": true,
      "expandable": false,
      "componentIconName": "header-info.svg",
      "bannerDirection": "ltr",
      "hideWhenNoDataAvailable": false,
      "showImage": false,
      "infoOnlyTextFormat": false,
      "audioAutoPlay": true
    }
  };

  baseURL = environment.apiURL + '/components';

  constructor(
    private http: HttpClient
  ) { }

  getEmptyComponent(){
    return of(this.emptyComponent);
  }

  getAllComponents(){
    return this.http.get<ComponentObj[]>(`${this.baseURL}/`,{ withCredentials: true });
  }

  getComponentById(id){
    return this.http.get<ComponentObj>(`${this.baseURL}/${id}`,{ withCredentials: true });
  }

  updateComponentMeta(metadata: any, id: string){
    return this.http.put(`${this.baseURL}/${id}/metadata/`, 
    {
      metadata: metadata
    }, { withCredentials: true }); 
  }
}
