import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListsService {

  constructor() { }

  getSupportedFontFamilies(){
    return of([
      { name: 'Arial', value: 'Arial, sans-serif' },
      { name: 'Helvetica', value: 'Helvetica, sans-serif' },
      { name: 'Times New Roman', value: 'Times New Roman, serif' },
      { name: 'Times', value: 'Times, serif' },
      { name: 'Courier New', value: 'Courier New, monospace' },
      { name: 'Courier', value: 'Courier, monospace' },
      { name: 'Georgia', value: 'Georgia, serif' },
      { name: 'Palatino', value: 'Palatino, serif' },
      { name: 'Verdana', value: 'Verdana, sans-serif' },
      { name: 'Geneva', value: 'Geneva, sans-serif' },
      { name: 'Tahoma', value: 'Tahoma, sans-serif' },
      { name: 'Trebuchet MS', value: 'Trebuchet MS, sans-serif' },
      { name: 'Impact', value: 'Impact, sans-serif' },
      // Add more font families as needed
    ]);
  }

  getSourceTypes(){
    return of([
      {
        id: 'coreOrienteeringMuseum',
        displayName: 'coreOrienteeringMuseum',
        description: 'coreOrienteeringMuseum.'
      },
      {
        id: 'experience',
        displayName: 'experience',
        description: 'experience.'
      },
      {
        id: 'indoorInteractiveImage',
        displayName: 'indoorInteractiveImage',
        description: 'indoorInteractiveImage.'
      },
      {
        id: 'indoorPuzzle',
        displayName: 'indoorPuzzle',
        description: 'indoorPuzzle.'
      },
      {
        id: 'mngsite',
        displayName: 'mngsite',
        description: 'coreOrientemngsiteeringMuseum.'
      },
      {
        id: 'mngsiteAdminUsers',
        displayName: 'mngsiteAdminUsers',
        description: 'mngsiteAdminUsers.'
      },
      {
        id: 'mngsiteOwner',
        displayName: 'mngsiteOwner',
        description: 'mngsiteOwner.'
      },
      {
        id: 'mngsiteUsers',
        displayName: 'mngsiteUsers',
        description: 'mngsiteUsers.'
      },
      {
        id: 'mngsiteUsersConnected',
        displayName: 'mngsiteUsersConnected',
        description: 'mngsiteUsersConnected.'
      },
      {
        id: 'museumRun',
        displayName: 'museumRun',
        description: 'museumRun.'
      },
      {
        id: 'museumRunSessionRole1',
        displayName: 'museumRunSessionRole1',
        description: 'museumRunSessionRole1.'
      },
      {
        id: 'museumRunSessionRole2',
        displayName: 'museumRunSessionRole2',
        description: 'museumRunSessionRole2.'
      },
      {
        id: 'museumRunSessionType1',
        displayName: 'museumRunSessionType1',
        description: 'museumRunSessionType1.'
      },
      {
        id: 'museumRunSessionType1overlay',
        displayName: 'museumRunSessionType1overlay',
        description: 'museumRunSessionType1overlay.'
      },
      {
        id: 'museumRunSessionType3',
        displayName: 'museumRunSessionType3',
        description: 'museumRunSessionType3.'
      },
      {
        id: 'museumRunSessionType4',
        displayName: 'museumRunSessionType4',
        description: 'museumRunSessionType4.'
      },
      {
        id: 'museumRunSessionType4Claimed',
        displayName: 'museumRunSessionType4Claimed',
        description: 'museumRunSessionType4Claimed.'
      },
      {
        id: 'museumRunSessionType6',
        displayName: 'museumRunSessionType6',
        description: 'museumRunSessionType6.'
      },
      {
        id: 'photoCurating',
        displayName: 'photoCurating',
        description: 'photoCurating.'
      },
      {
        id: 'placeRaceAdvance',
        displayName: 'placeRaceAdvance',
        description: 'placeRaceAdvance.'
      },
      {
        id: 'portal',
        displayName: 'portal',
        description: 'portal.'
      },
      {
        id: 'portalOneSite',
        displayName: 'portalOneSite',
        description: 'portalOneSite.'
      },
      {
        id: 'portalSite',
        displayName: 'portalSite',
        description: 'portalSite.'
      },
      {
        id: 'portalSiteMosaico',
        displayName: 'portalSiteMosaico',
        description: 'portalSiteMosaico.'
      }
    ]);
  }

  getUserRolesList(){
    return of([
      {
          id: 'editors',
          displayName: 'Editors',
          description: 'Editors.'
      },
      {
        id: 'certifiers',
        displayName: 'Certifiers',
        description: 'Certifiers.'
      },
    ]);
  }

  getButtonActionTypesList(){
    return of([
      {
          id: 'gotoPage',
          displayName: 'Go To Page',
          description: 'Go To Page.'
      },
      {
        id: 'close',
        displayName: 'Close',
        description: 'close.'
      },
      {
        id: 'navigate',
        displayName: 'Navigate',
        description: 'navigate.'
      },
      {
        id: 'timer',
        displayName: 'Timer',
        description: 'timer.'
      },
      {
        id: 'gotoStation',
        displayName: 'Go To Station(ffu)',
        description: 'gotoStation(ffu).'
      },
      {
        id: 'gotoLink',
        displayName: 'Go To Link (ffu)',
        description: 'gotoLink (ffu).'
      },
    ]);
  }

  getBindingFieldsList(){
    return of([
      {
          id: 'stations.idStations',
          displayName: 'idStations',
          description: 'idStations.'
      },
      {
        id: 'stations.Name',
        displayName: 'Name',
        description: 'Name.'
      },
      {
        id: 'stations.Email',
        displayName: 'Email',
        description: 'Email.'
      },
      {
        id: 'stations.WaypointLatitude',
        displayName: 'WaypointLatitude',
        description: 'WaypointLatitude.'
      },
      {
        id: 'stations.WaypointLongitude',
        displayName: 'WaypointLongitude',
        description: 'WaypointLongitude.'
      },
      {
        id: 'stations.Address',
        displayName: 'Address',
        description: 'Address.'
      },
      {
        id: 'stations.Description',
        displayName: 'Description',
        description: 'Description.'
      },
      {
        id: 'stations.ArrMethod',
        displayName: 'ArrMethod',
        description: 'ArrMethod.'
      },
      {
        id: 'stations.ArrQuestion',
        displayName: 'ArrQuestion',
        description: 'ArrQuestion.'
      },
      {
        id: 'stations.ArrQuestionMedia',
        displayName: 'ArrQuestionMedia',
        description: 'ArrQuestionMedia.'
      },
      {
        id: 'stations.ArrVisualDescription',
        displayName: 'ArrVisualDescription',
        description: 'ArrVisualDescription.'
      },
      {
        id: 'stations.Upload_type',
        displayName: 'Upload_type',
        description: 'Upload_type.'
      },
      {
        id: 'stations.ArrAnswer',
        displayName: 'ArrAnswer',
        description: 'ArrAnswer.'
      },
      {
        id: 'stations.ArrRight',
        displayName: 'ArrRight',
        description: 'ArrRight.'
      },
      {
        id: 'stations.ArrWrong',
        displayName: 'ArrWrong',
        description: 'ArrWrong.'
      },
      {
        id: 'stations.Interaction',
        displayName: 'Interaction',
        description: 'Interaction.'
      },
      {
        id: 'stations.Action',
        displayName: 'Action',
        description: 'Action.'
      },
      {
        id: 'stations.ActionQuestion',
        displayName: 'ActionQuestion',
        description: 'ActionQuestion.'
      },
      {
        id: 'stations.ActionAnswer',
        displayName: 'ActionAnswer',
        description: 'ActionAnswer.'
      },
      {
        id: 'stations.ActionRight',
        displayName: 'ActionRight',
        description: 'ActionRight.'
      },
      {
        id: 'stations.ActionWrong',
        displayName: 'ActionWrong',
        description: 'ActionWrong.'
      },
      {
        id: 'stations.DeveloperFeedback',
        displayName: 'DeveloperFeedback',
        description: 'DeveloperFeedback.'
      },
      {
        id: 'stations.UserMedia',
        displayName: 'UserMedia',
        description: 'UserMedia.'
      },
      {
        id: 'stations.UserFeedback',
        displayName: 'UserFeedback',
        description: 'UserFeedback.'
      },
      {
        id: 'stations.UserLearning',
        displayName: 'UserLearning',
        description: 'UserLearning.'
      },
      {
        id: 'stations.Rating',
        displayName: 'Rating',
        description: 'Rating.'
      },
      {
        id: 'stations.DeveloperLearning',
        displayName: 'DeveloperLearning',
        description: 'DeveloperLearning.'
      },
      {
        id: 'stations.PicasaImage',
        displayName: 'PicasaImage',
        description: 'PicasaImage.'
      },
      {
        id: 'stations.CertifyPrivate',
        displayName: 'CertifyPrivate',
        description: 'CertifyPrivate.'
      },
      {
        id: 'stations.CertifyPublic',
        displayName: 'CertifyPublic',
        description: 'CertifyPublic.'
      },
      {
        id: 'stations.Certify',
        displayName: 'Certify',
        description: 'Certify.'
      },
      {
        id: 'stations.TimeStamp',
        displayName: 'TimeStamp',
        description: 'TimeStamp.'
      },
      {
        id: 'stations.GetToPlace',
        displayName: 'GetToPlace',
        description: 'GetToPlace.'
      },
      {
        id: 'stations.ClaimCode',
        displayName: 'ClaimCode',
        description: 'ClaimCode.'
      },
      
    ]);
  }

  getBindingFields12List(){
    return of([
      {
          id: 'answers.ArrAnswer1',
          displayName: 'ArrAnswer1',
          description: 'ArrAnswer1.'
      },
      {
        id: 'answers.ArrAnswer2',
        displayName: 'ArrAnswer2',
        description: 'ArrAnswer2.'
      },
      {
        id: 'answers.ArrAnswer3',
        displayName: 'ArrAnswer3',
        description: 'ArrAnswer3.'
      },
      {
        id: 'answers.ArrAnswer4',
        displayName: 'ArrAnswer4',
        description: 'ArrAnswer4.'
      },
      {
        id: 'answers.ArrAnswer5',
        displayName: 'ArrAnswer5',
        description: 'ArrAnswer5.'
      },
      {
        id: 'answers.ArrAnswer6',
        displayName: 'ArrAnswer6',
        description: 'ArrAnswer6.'
      },
      {
        id: 'answers.ArrAnswer7',
        displayName: 'ArrAnswer7',
        description: 'ArrAnswer7.'
      },
      {
        id: 'answers.ArrAnswer8',
        displayName: 'ArrAnswer8',
        description: 'ArrAnswer8.'
      },
      {
        id: 'answers.ArrAnswer9',
        displayName: 'ArrAnswer9',
        description: 'ArrAnswer9.'
      },
      {
        id: 'answers.ArrAnswer10',
        displayName: 'ArrAnswer10',
        description: 'ArrAnswer10.'
      },
      {
        id: 'answers.ArrAnswer11',
        displayName: 'ArrAnswer11',
        description: 'ArrAnswer11.'
      },
      {
        id: 'answers.ArrAnswer12',
        displayName: 'ArrAnswer12',
        description: 'ArrAnswer12.'
      },
      {
        id: 'answers.ArrAnswer13',
        displayName: 'ArrAnswer13',
        description: 'ArrAnswer13.'
      },
      {
        id: 'answers.ArrAnswer14',
        displayName: 'ArrAnswer14',
        description: 'ArrAnswer14.'
      },
      {
        id: 'answers.ArrAnswer15',
        displayName: 'ArrAnswer15',
        description: 'ArrAnswer15.'
      },
      {
        id: 'answers.ArrAnswer16',
        displayName: 'ArrAnswer16',
        description: 'ArrAnswer16.'
      },
      {
        id: 'answers.ArrAnswer17',
        displayName: 'ArrAnswer17',
        description: 'ArrAnswer17.'
      },
      {
        id: 'answers.ArrAnswer18',
        displayName: 'ArrAnswer18',
        description: 'ArrAnswer18.'
      },
      {
        id: 'answers.ArrAnswer19',
        displayName: 'ArrAnswer19',
        description: 'ArrAnswer19.'
      },
      {
        id: 'answers.ArrAnswer20',
        displayName: 'ArrAnswer20',
        description: 'ArrAnswer20.'
      },
      {
        id: 'answers.ActionAnswer1',
        displayName: 'ActionAnswer1',
        description: 'ActionAnswer1.'
      },
      {
        id: 'answers.ActionAnswer2',
        displayName: 'ActionAnswer2',
        description: 'ActionAnswer2.'
      },
      {
        id: 'answers.ActionAnswer3',
        displayName: 'ActionAnswer3',
        description: 'ActionAnswer3.'
      },
      {
        id: 'answers.ActionAnswer4',
        displayName: 'ActionAnswer4',
        description: 'ActionAnswer4.'
      },
      {
        id: 'answers.ActionAnswer5',
        displayName: 'ActionAnswer5',
        description: 'ActionAnswer5.'
      },
      {
        id: 'answers.ActionAnswer6',
        displayName: 'ActionAnswer6',
        description: 'ActionAnswer6.'
      },
      {
        id: 'answers.ActionAnswer7',
        displayName: 'ActionAnswer7',
        description: 'ActionAnswer7.'
      },
      {
        id: 'answers.ActionAnswer8',
        displayName: 'ActionAnswer8',
        description: 'ActionAnswer8.'
      },
      {
        id: 'answers.ActionAnswer9',
        displayName: 'ActionAnswer9',
        description: 'ActionAnswer9.'
      },
      {
        id: 'answers.ActionAnswer10',
        displayName: 'ActionAnswer10',
        description: 'ActionAnswer10.'
      },
      {
        id: 'answers.ActionAnswer11',
        displayName: 'ActionAnswer11',
        description: 'ActionAnswer11.'
      },
      {
        id: 'answers.ActionAnswer12',
        displayName: 'ActionAnswer12',
        description: 'ActionAnswer12.'
      },
      {
        id: 'answers.ActionAnswer13',
        displayName: 'ActionAnswer13',
        description: 'ActionAnswer13.'
      },
      {
        id: 'answers.ActionAnswer14',
        displayName: 'ActionAnswer14',
        description: 'ActionAnswer14.'
      },
      {
        id: 'answers.ActionAnswer15',
        displayName: 'ActionAnswer15',
        description: 'ActionAnswer15.'
      },
      {
        id: 'answers.ActionAnswer16',
        displayName: 'ActionAnswer16',
        description: 'ActionAnswer16.'
      },
      {
        id: 'answers.ActionAnswer17',
        displayName: 'ActionAnswer17',
        description: 'ActionAnswer17.'
      },
      {
        id: 'answers.ActionAnswer18',
        displayName: 'ActionAnswer18',
        description: 'ActionAnswer18.'
      },
      {
        id: 'answers.ActionAnswer19',
        displayName: 'ActionAnswer19',
        description: 'ActionAnswer19.'
      },
      {
        id: 'answers.ActionAnswer20',
        displayName: 'ActionAnswer20',
        description: 'ActionAnswer20.'
      },
      {
        id: 'tags.People1',
        displayName: 'People1',
        description: 'People1.'
      },
      {
        id: 'tags.People2',
        displayName: 'People2',
        description: 'People2.'
      },
      {
        id: 'tags.People3',
        displayName: 'People3',
        description: 'People3.'
      },
      {
        id: 'tags.People4',
        displayName: 'People4',
        description: 'People4.'
      },
      {
        id: 'tags.People5',
        displayName: 'People5',
        description: 'People5.'
      },
      {
        id: 'tags.People6',
        displayName: 'People6',
        description: 'People6.'
      },
      {
        id: 'tags.People7',
        displayName: 'People7',
        description: 'People7.'
      },
      {
        id: 'tags.Area1',
        displayName: 'Area1',
        description: 'Area1.'
      },
      {
        id: 'tags.Area2',
        displayName: 'Area2',
        description: 'Area2.'
      },
      {
        id: 'tags.Area3',
        displayName: 'Area3',
        description: 'Area3.'
      },
      {
        id: 'tags.Area4',
        displayName: 'Area4',
        description: 'Area4.'
      },
      {
        id: 'tags.Area5',
        displayName: 'Area5',
        description: 'Area5.'
      },
      {
        id: 'tags.Area6',
        displayName: 'Area6',
        description: 'Area6.'
      },
      {
        id: 'tags.Area7',
        displayName: 'Area7',
        description: 'Area7.'
      },
      {
        id: 'tags.Perception1',
        displayName: 'Perception1',
        description: 'Perception1.'
      },
      {
        id: 'tags.Perception2',
        displayName: 'Perception2',
        description: 'Perception2.'
      },
      {
        id: 'tags.Perception3',
        displayName: 'Perception3',
        description: 'Perception3.'
      },
      {
        id: 'tags.Perception4',
        displayName: 'Perception4',
        description: 'Perception4.'
      },
      {
        id: 'tags.Perception5',
        displayName: 'Perception5',
        description: 'Perception5.'
      },
      {
        id: 'tags.Perception6',
        displayName: 'Perception6',
        description: 'Perception6.'
      },
      {
        id: 'tags.Perception7',
        displayName: 'Perception7',
        description: 'Perception7.'
      },
      {
        id: 'tags.Time1',
        displayName: 'Time1',
        description: 'Time1.'
      },
      {
        id: 'tags.Time2',
        displayName: 'Time2',
        description: 'Time2.'
      },
      {
        id: 'tags.Time3',
        displayName: 'Time3',
        description: 'Time3.'
      },
      {
        id: 'tags.Time4',
        displayName: 'Time4',
        description: 'Time4.'
      },
      {
        id: 'tags.Time5',
        displayName: 'Time5',
        description: 'Time5.'
      },
      {
        id: 'tags.Time6',
        displayName: 'Time6',
        description: 'Time6.'
      },
      {
        id: 'tags.Time7',
        displayName: 'Time7',
        description: 'Time7.'
      },
      {
        id: 'tags.Space1',
        displayName: 'Space1',
        description: 'Space1.'
      },
      {
        id: 'tags.Space2',
        displayName: 'Space2',
        description: 'Space2.'
      },
      {
        id: 'tags.Space3',
        displayName: 'Space3',
        description: 'Space3.'
      },
      {
        id: 'tags.Space4',
        displayName: 'Space4',
        description: 'Space4.'
      },
      {
        id: 'tags.Space5',
        displayName: 'Space5',
        description: 'Space5.'
      },
      {
        id: 'tags.Space6',
        displayName: 'Space6',
        description: 'Space6.'
      },
      {
        id: 'tags.Space7',
        displayName: 'Space7',
        description: 'Space7.'
      },
      {
        id: 'tags.Verb1',
        displayName: 'Verb1',
        description: 'Verb1.'
      },
      {
        id: 'tags.Verb2',
        displayName: 'Verb2',
        description: 'Verb2.'
      },
      {
        id: 'tags.Verb3',
        displayName: 'Verb3',
        description: 'Verb3.'
      },
      {
        id: 'tags.Verb4',
        displayName: 'Verb4',
        description: 'Verb4.'
      },
      {
        id: 'tags.Verb5',
        displayName: 'Verb5',
        description: 'Verb5.'
      },
      {
        id: 'tags.Verb6',
        displayName: 'Verb6',
        description: 'Verb6.'
      },
      {
        id: 'tags.Verb7',
        displayName: 'Verb7',
        description: 'Verb7.'
      },
      {
        id: 'tags.Adjective1',
        displayName: 'Adjective1',
        description: 'Adjective1.'
      },
      {
        id: 'tags.Adjective2',
        displayName: 'Adjective2',
        description: 'Adjective2.'
      },
      {
        id: 'tags.Adjective3',
        displayName: 'Adjective3',
        description: 'Adjective3.'
      },
      {
        id: 'tags.Adjective4',
        displayName: 'Adjective4',
        description: 'Adjective4.'
      },
      {
        id: 'tags.Adjective5',
        displayName: 'Adjective5',
        description: 'Adjective5.'
      },
      {
        id: 'tags.Adjective6',
        displayName: 'Adjective6',
        description: 'Adjective6.'
      },
      {
        id: 'tags.Adjective7',
        displayName: 'Adjective7',
        description: 'Adjective7.'
      },
      {
        id: 'tags.Misc1',
        displayName: 'Misc1',
        description: 'Misc1.'
      },
      {
        id: 'tags.Misc2',
        displayName: 'Misc2',
        description: 'Misc2.'
      },
      {
        id: 'tags.Misc3',
        displayName: 'Misc3',
        description: 'Misc3.'
      },
      {
        id: 'tags.Misc4',
        displayName: 'Misc4',
        description: 'Misc4.'
      },
      {
        id: 'tags.Misc5',
        displayName: 'Misc5',
        description: 'Misc5.'
      },
      {
        id: 'tags.Misc6',
        displayName: 'Misc6',
        description: 'Misc6.'
      },
      {
        id: 'tags.Misc7',
        displayName: 'Misc7',
        description: 'Misc7.'
      }
    ]);
  }
  
  getEventTypesList(){
    return of([
      {
          id: 'claimStation',
          displayName: 'Claim Station',
          description: 'Claim station events.'
      },
      {
        id: 'closeCard',
        displayName: 'Close Card',
        description: 'Close card events.'
      }
    ]);
  }

  getShareServicesList(){
    return of([
      {
          id: 'google_plusone_share',
          displayName: 'Google Plus',
          description: 'Google Plus.'
      },
      {
        id: 'facebook',
        displayName: 'Facebook',
        description: 'Facebook.'
      },
      {
        id: 'twitter',
        displayName: 'Twitter',
        description: 'twitter.'
      },
      {
        id: 'pinterest_share',
        displayName: 'Pinterest',
        description: 'pinterest_share.'
      },
      {
        id: 'email',
        displayName: 'Email',
        description: 'email.'
      },
      {
        id: 'whatsapp',
        displayName: 'WhatsApp',
        description: 'whatsapp.'
      }
    ]);
  }

  /* getEventsList(){
    return of([
        {
            id: 'commentAdded',
            displayName: 'Comment Added',
            description: 'This event is fired when a comment is added.'
        },
        {
            id: 'placeholderCompleted',
            displayName: 'Placeholder Completed',
            description: 'This event is fired when placeholder is completed.'
        },
        {
            id: 'commentMediaCommentUploaded',
            displayName: 'Comment Media Comment Uploaded',
            description: 'This event is fired when comment media and comment is uploaded.'
        },
        {
            id: 'verificationArrivalAnsweredRight',
            displayName: 'Verification Arrival Answered Right',
            description: 'This event is fired when verification arrival is answered right.'
        },
        {
          id: 'verificationArrivalAnsweredWrong',
          displayName: 'Verification Arrival Answered Wrong',
          description: 'This event is fired when Verification Arrival Answered is Wrong.'
        },
        {
          id: 'questionAnsweredRight',
          displayName: 'Question Answered Right',
          description: 'This event is fired when Question Answered is Right.'
        },
        {
          id: 'questionAnsweredWrong',
          displayName: 'Question Answered Wrong',
          description: 'This event is fired when Question Answered is Wrong.'
        },
        {
          id: 'textualArrived',
          displayName: 'Textual Arrived',
          description: 'This event is fired when Textual Arrived.'
        },
        {
          id: 'timeFinished',
          displayName: 'Time Finished',
          description: 'Time is Finished'
        },
        {
          id: 'timeStarted',
          displayName: 'Time Started',
          description: 'Time is Started'
        },
        {
          id: 'mediaElementUploaded',
          displayName: 'Media Element Uploaded',
          description: 'Media Element is Uploaded'
        },
        {
          id: 'visualArrived',
          displayName: 'Visual Arrived',
          description: 'Visual Arrived'
        },
        {
          id: 'dataPointAdded',
          displayName: 'Data Point Added',
          description: 'Data Point Added'
        },
        {
          id: 'voicePlay',
          displayName: 'Voice Play',
          description: 'Voice Play'
        },
        {
          id: 'voiceStopped',
          displayName: 'Voice Stopped',
          description: 'Voice Stopped'
        },
        {
          id: 'overlayArrivalAnsweredRight',
          displayName: 'Overlay Arrival Answered Right',
          description: 'Overlay Arrival Answered Right'
        },
        {
          id: 'overlayArrivalAnsweredWrong',
          displayName: 'Overlay Arrival Answered Wrong',
          description: 'Overlay Arrival Answered Wrong'
        },
        {
          id: 'openQuestionAnswered',
          displayName: 'Open Question Answered',
          description: 'Open Question Answered'
        },
        {
          id: 'actionButtonClicked',
          displayName: 'Action Button Clicked',
          description: 'Action Button Clicked'
        },
        {
          id: 'timerIntervalLapsed',
          displayName: 'Timer Interval Lapsed',
          description: 'Timer Interval Lapsed'
        },
        {
          id: 'puzzleArrivalSolved',
          displayName: 'Puzzle Arrival Solved',
          description: 'Puzzle Arrival Solved'
        },
        {
          id: 'missionInteractiveCompleted',
          displayName: 'Mission Interactive Completed',
          description: 'Mission Interactive Completed'
        }
    ]);
  } */

  getActionsList(){
    return of([
        {
          id: 'play',
          displayName: 'Play',
          description: 'Play Action.'
        },
        {
          id: 'stop',
          displayName: 'Stop',
          description: 'Stop Action.'
        },
        {
          id: 'triggerOwnEvent',
          displayName: 'Trigger Own Event',
          description: 'Trigger Own Event.'
        },
        {
          id: 'playAudio',
          displayName: 'Play Audio',
          description: 'Play Audio.'
        },
        {
          id: 'startTimer',
          displayName: 'Start Timer',
          description: 'Start Timer.'
        },
        {
          id: 'flip',
          displayName: 'Flip',
          description: 'Flip.'
        }
    ]);
  }

}
