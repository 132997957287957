import { Component, OnInit, Input } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ButtonsService } from 'src/app/services/buttons.service';

import { Button } from 'src/app/objects/button';
import { ListsService } from 'src/app/objects/lists';
import { FrameService } from 'src/app/services/frame.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { first } from 'rxjs/operators';
import { Page } from 'src/app/objects/page';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-buttons-editor',
  templateUrl: './buttons-editor.component.html',
  styleUrls: ['./buttons-editor.component.css']
})
export class ButtonsEditorComponent implements OnInit {

  @Input() frame: any;
  @Input() button: any;
  @Input() page: any;
  @Input() frameButtons: any;
  @Input() buttonTypes: any;
  @Input() framePages: any;

  frameValue: any = null;
  buttonValue: Button = null;
  pageValue: Page = null;
  frameButtonsValue: [] = null;
  buttonTypesValue: [] = null;
  framePagesValue: [] = null;

  buttonForm: FormGroup = null;
  newButtonForm: FormGroup = null;

  errorMessage: string = '';
  showSpinnerForm: boolean = true;
  public events: any;

  public buttonsType: any = null;

  public frameButtonId: any = null;
  public buttonAction: string = 'edit';

  public stationId: any = null;
  public frameId: any = null;

  buttonPresets: any = null;
  buttonPreset: any = null;
  buttonPresetID: any = null;
  activeButton: any = null;
  activeButtonID: any = null;
  activeButtonForm: FormGroup = null;
  buttonsMenuForm: FormGroup = null;
  buttonsMenu: any = null;
  menuButtons: [] = null;
  buttonFormArray: Array<FormGroup> = [];
  public buttonActionTypes: any;
  public buttonsMenuFormType: string = 'add';

  public icons: any = null;

  constructor(
    public buttonService: ButtonsService, 
    public framesService: FrameService,
    public lists: ListsService, 
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private generalService: GeneralService,
    private router: Router) { 
      this.route.queryParams.subscribe(params => {
        this.stationId = params['stationId'];
        this.frameId = params['id'];
      });
    }

  ngOnChanges(): void {

    if(this.frame){
      this.frame.subscribe((x: any) => {
        //console.log(x);
        this.showSpinnerForm = true;
        this.frameValue = x;
        if(this.frameValue.metadata){
          let metadata = JSON.parse(this.frameValue.metadata);
          this.buttonsType = metadata.buttonsType;
        }else{
          this.buttonsType = this.frameValue.buttonsType;
        }
        //console.log(this.buttonsType);
        if(this.buttonsType === 'buttonsMenu'){
          this.prepareFrameButtonsMenuData();
        }
      });
    }

    if(this.frameButtons){
      this.frameButtons.subscribe((x: any) => {
        this.showSpinnerForm = true;
        this.frameButtonsValue = x;
        this.showSpinnerForm = false;
      });
    }

    if(this.buttonTypes){
      this.buttonTypes.subscribe((x: any) => {
        this.showSpinnerForm = true;
        this.buttonTypesValue = x;
        this.showSpinnerForm = false;
      });
    }

    if(this.framePages){
      this.framePages.subscribe((x: any) => {
        this.showSpinnerForm = true;
        this.framePagesValue = x;
        this.showSpinnerForm = false;
      });
    }

    if(this.button){
      this.button.subscribe((x) => {
        this.showSpinnerForm = true;
        if(x){
          this.buttonValue = x;
          if(this.buttonValue['stationcard_button']){
            let buttonColumns = this.buttonValue['stationcard_button'];
            let buttonMeta = buttonColumns.metadata;
            if(buttonMeta){
              buttonMeta = JSON.parse(buttonMeta);
            }
            this.buttonValue['metadata'] = buttonMeta;
          }
          this.frameButtonId = this.buttonValue['id'];
          this.populateForm();
        }else{
          this.buttonValue = null;
          this.frameButtonId = null;
        }
        if(!this.frameButtonsValue || !this.frameButtonsValue.length){
          this.buttonAction = 'delete';
        }else{
          this.buttonAction = 'edit';
        }
        this.showSpinnerForm = false;
      });
    }

    if(this.page !== null){
      this.page.subscribe((x) => {
        this.pageValue = x;
      });
    }

    this.showSpinnerForm = false;
  }

  prepareFrameButtonsMenuData(){
    this.framesService.getFrameButtonsMenu(this.frameId).subscribe((res: any) => {
      //console.log(res);
      if(res){
        this.buttonsMenu = res;
        this.buttonsMenuFormType = 'edit';
        this.prepareButtonsData(res['buttons']);
      }else{
        this.buttonsMenu = {
          "menuType": "banner",
          "collapsiblePosition": "left",
          "menuLocation": "bottom",
          "menuDirection": "ltr",
          "backgroundColor": "#000000",
          "colorOpacity": 0
        };
        this.buttonsMenuFormType = 'add';
      }
      this.createButtonsMenuForm();
      this.showSpinnerForm = false;
    });
  }

  ngOnInit() {
    this.settingsService.getSettingsByType('events_list').subscribe((res: any) => {
      if(res){
        this.events = JSON.parse(res['metadata']);
      }
    });

    this.lists.getButtonActionTypesList().subscribe((items: any) => {
      this.buttonActionTypes = items;
    });

    this.generalService.getAllIcons().subscribe((res: any) => {
      if(res && res.length){
        this.icons = res;
      }
    });

    this.prepareButtonsData();
  }

  onSelectionChange(event: any, i: any){
    if(event.value == 'gotoPage'){
      this.buttonFormArray[i].get('buttonActionDetails').setValue(null);
      this.buttonFormArray[i].get('buttonIcon').setValue('fa-picture-o');
    }
    if(event.value == 'navigate'){
      this.buttonFormArray[i].get('buttonActionDetails').setValue('next');
      this.buttonFormArray[i].get('buttonIcon').setValue('fa-chevron-right');
    }
    if(event.value == 'close'){
      this.buttonFormArray[i].get('buttonActionDetails').setValue('close');
      this.buttonFormArray[i].get('buttonIcon').setValue('fa-times');
    }
    if(event.value == 'timer'){
      this.buttonFormArray[i].get('buttonActionDetails').setValue('03:00');
    }
    if(event.value == 'gotoStation'){
      this.buttonFormArray[i].get('buttonActionDetails').setValue('15000');
      this.buttonFormArray[i].get('buttonIcon').setValue('fa-plain');
    }
    if(event.value == 'gotoLink'){
      this.buttonFormArray[i].get('buttonActionDetails').setValue('http://muse.run');
      this.buttonFormArray[i].get('buttonIcon').setValue('fa-link');
    }
  }

  prepareButtonsData(data = null){
    if(this.buttonsMenuFormType === 'add' || (data && data.length === 0)){
      this.settingsService.getSettingsByType('button_presets').subscribe((res: any) => {
        if(res){
          this.buttonPresets = JSON.parse(res['metadata']);
          this.buttonPreset = this.buttonPresets[0];
          this.buttonPresetID = 0;
          this.menuButtons = this.buttonPreset.buttons;
          this.activeButton = this.buttonPreset.buttons[0];
          this.activeButtonID = 0;
          this.createButtonsForms();
          this.showSpinnerForm = false;
        }
      });
    }else{
      this.buttonPresetID = 10000;
      this.menuButtons = data;
      this.activeButton = data[0];
      this.activeButtonID = 0;
      this.createButtonsForms();
      this.showSpinnerForm = false;
    }
    //console.log(this.menuButtons);
  }

  createButtonsMenuForm() {
    let formGroup: any = {};
    //console.log(this.buttonsMenu);
    if(this.buttonsMenu !== null){
      for (let [key, value] of Object.entries(this.buttonsMenu)) {
        formGroup[key] = new FormControl(this.buttonsMenu[key]);
      }
      this.buttonsMenuForm = this.fb.group(formGroup);
      //console.log(this.buttonsMenuForm);
    }
  }

  createButtonsForms() {
    this.buttonFormArray = [];
    if(this.buttonPreset !== null){
      this.menuButtons.forEach((element) => {
        let formGroup: any = {};
        for (let [key, value] of Object.entries(element)) {
          formGroup[key] = new FormControl(element[key]);
        }
        this.buttonFormArray.push(this.fb.group(formGroup));
      });
    }
  }

  handleActiveButtonPreset(buttonPresetIndex){
    this.buttonPreset = this.buttonPresets[buttonPresetIndex];
    this.menuButtons = this.buttonPreset.buttons;
    this.buttonPresetID = buttonPresetIndex;
    this.createButtonsForms();
  }

  saveButtonsMenuForm(form: any){
    let formValue = form.value;
    let buttonsArray = [];
    this.buttonFormArray.forEach(element => {
      buttonsArray.push(element.value);
    });
    formValue.buttons = buttonsArray;
    if(this.buttonsMenuFormType === 'edit'){
      this.showSpinnerForm = true;
      this.framesService.updateFrameButtonsMenu(this.frameId, formValue.id, formValue).pipe(first()).subscribe({
        next: (response: any) => {
          this.alertService.success('The Buttons Menu is Updated Successfully!', { keepAfterRouteChange: true });
          this.prepareFrameButtonsMenuData();
          this.alertService.success('The Buttons Menu is Updated Successfully!', { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
        },
        error: error => {
            this.alertService.error(error, { keepAfterRouteChange: true });
            this.showSpinnerForm = false;
        }
      });
    }else{
      this.showSpinnerForm = true;
      this.framesService.addFrameButtonsMenu(this.frameId, formValue).pipe(first()).subscribe({
        next: (response:any) => {
          this.prepareFrameButtonsMenuData();
          this.alertService.success('The Buttons Menu is Added Successfully!', { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
        },
        error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
        }
      });
    }
    
  }

  createActiveButtonForm() {
    let formGroup: any = {};
    let formFields: any = [];
    for (let [key, value] of Object.entries(this.activeButton)) {
      let field: any = {};
      formGroup[key] = new FormControl(value, Validators.required);
      formFields.push(field);
    }
    this.activeButtonForm = this.fb.group(formGroup);
  }

  saveActiveButtonForm(formValue: any){
    let index = this.buttonPresets[this.buttonPresetID].buttons.indexOf(this.button);
    if (index !== -1) {
      this.buttonPresets[this.buttonPresetID].buttons[index] = formValue;
    }
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinnerForm = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
          this.router.navigate(['/settings'],{});
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    let buttonsArray = this.buttonPresets[this.buttonPresetID].buttons;
    buttonsArray[event.previousIndex].position = 'M'+ (event.currentIndex);
    buttonsArray[event.currentIndex].position = 'M'+ (event.previousIndex);
    moveItemInArray(buttonsArray, event.previousIndex, event.currentIndex);
    this.showSpinnerForm = true;
    
    this.buttonPresets[this.buttonPresetID].buttons = buttonsArray;
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinnerForm = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
        this.buttonPresets = JSON.parse(response['metadata']);
        this.buttonPreset = this.buttonPresets[this.buttonPresetID];
        this.button = this.buttonPreset.buttons[0];
        this.activeButtonID = 0;
        this.createActiveButtonForm();
        this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
        this.router.navigate(['/settings'],{});
        this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleAddActiveButton(){
    this.showSpinnerForm = true;
    let currentButtons = this.buttonPresets[this.buttonPresetID].buttons;
    this.buttonPresets[this.buttonPresetID].buttons.push({
      "position": 'M'+ (currentButtons.length),
      "eventToListenToShow": null,
      "eventToListenToHide": null,
      "eventToListenToEnable": null,
      "eventToListenToDisable": null,
      "actionType": "gotoPage",
      "buttonText": "Location",
      "buttonIcon": "fa-map",
      "buttonBGColor": "#ffffff",
      "buttonIconColor": "#333333",
      "buttonTextColor": "#333333",
      "buttonSelectedColor": "#454545",
      "buttonActionDetails": "451"
    });
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinnerForm = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
        this.buttonPresets = JSON.parse(response['metadata']);
        this.buttonPreset = this.buttonPresets[this.buttonPresetID];
        this.button = this.buttonPreset.buttons[0];
        this.activeButtonID = 0;
        this.createActiveButtonForm();
        this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
        this.router.navigate(['/settings'],{});
        this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleDeleteActiveButton(){
    this.showSpinnerForm = true;
    const index = this.buttonPresets[this.buttonPresetID].buttons.indexOf(this.button);
    if (index > -1) {
      this.buttonPresets[this.buttonPresetID].buttons.splice(index, 1);
    }
    let metadata = JSON.stringify(this.buttonPresets);
    this.showSpinnerForm = true;
    this.settingsService.updateSettingsByType('button_presets', metadata).pipe(first()).subscribe({
      next: (response:any) => {
        this.buttonPresets = JSON.parse(response['metadata']);
        this.buttonPreset = this.buttonPresets[this.buttonPresetID];
        this.button = this.buttonPreset.buttons[0];
        this.activeButtonID = 0;
        this.createActiveButtonForm();
        this.alertService.success('The button presets are updated successfully!', { keepAfterRouteChange: true });
        this.router.navigate(['/settings'],{});
        this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleChange(event:any, source: string){
    let newValue = this.button.value;
    if(source === 'cardStyle'){
      newValue.cardStyle = event.value;
    }else if(source === 'langType'){
      newValue.langType = event.value;
    }
    else if(source === 'frameNavType'){
      newValue.frameNavType = event.value;
    }
    this.button.next(newValue);
  }

  populateForm(){
    this.createForm();
  }

  createForm() {
    this.buttonForm = this.fb.group({
      id: [{value: this.buttonValue['id'], disabled: true}, Validators.required],
      goToPageId: [this.buttonValue.goToPageId],
      buttonId: [this.buttonValue['buttonId']],
      position: [this.buttonValue.position],
      eventToListenToShow: [this.buttonValue.eventToListenToShow],
      eventToListenToHide: [this.buttonValue.eventToListenToHide],
      eventToListenToEnable: [this.buttonValue.eventToListenToEnable],
      eventToListenToDisable: [this.buttonValue.eventToListenToDisable]
    });
  }

  saveForm(formValue: Button){
    let updatedButton: any = {};
    updatedButton.goToPageId = (formValue.goToPageId) ? formValue.goToPageId : 0;
    updatedButton.buttonId = formValue['buttonId'];
    updatedButton.position = formValue.position;
    let metadata = null;
    this.buttonTypesValue.forEach(element => {
      if(element['id'] === parseInt(updatedButton.buttonId)){
        metadata = element['metadata'];
      }
    });
    updatedButton.metadata = metadata;
    updatedButton.eventToListenToShow = formValue.eventToListenToShow;
    updatedButton.eventToListenToHide = formValue.eventToListenToHide;
    updatedButton.eventToListenToEnable = formValue.eventToListenToEnable;
    updatedButton.eventToListenToDisable = formValue.eventToListenToDisable;

    this.showSpinnerForm = true;
    this.framesService.updateFrameButton(this.frameId, this.frameButtonId, updatedButton).pipe(first()).subscribe({
      next: (response: any) => {
        this.alertService.success('The button is updated successfully!', { keepAfterRouteChange: true });
        if(this.frameButtons){
          this.frameButtons.next(response);
          if(response.length){
            if(this.button){
              this.button.next(response[0]);
            }else{
              this.button = new BehaviorSubject<Button>(response[0]);
            }
          }else{
            if(this.button){
              this.button.next(null);
            }else{
              this.button = new BehaviorSubject<Button>(null);
            }
          }
        }else{
          this.frameButtons = new BehaviorSubject<Button[]>(response);
        }
        const queryParams: Params = { activeTab: 2 };
        this.router.navigate(
          [], 
          {
            relativeTo: this.route,
            queryParams: queryParams, 
            queryParamsHandling: 'merge',
          }
        );
        this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleDelete(){
    this.showSpinnerForm = true;
    this.framesService.deleteFrameButton(this.frameId, this.frameButtonId).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The frame button is successfully deleted!', { keepAfterRouteChange: true });
          if(this.frameButtons){
            this.frameButtons.next(response);
            if(response.length){
              if(this.button){
                this.button.next(response[0]);
              }else{
                this.button = new BehaviorSubject<Button>(response[0]);
              }
            }else{
              if(this.button){
                this.button.next(null);
              }else{
                this.button = new BehaviorSubject<Button>(null);
              }
            }
          }else{
            this.frameButtons = new BehaviorSubject<Button[]>(response);
          }
          const queryParams: Params = { activeTab: 2 };
          this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: queryParams, 
              queryParamsHandling: 'merge',
            }
          );
          if(!this.frameButtonsValue || !this.frameButtonsValue.length){
            this.buttonAction = 'delete';
          }else{
            this.buttonAction = 'edit';
          }
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleMenuButtonDelete(id: any){
    this.showSpinnerForm = true;
    this.framesService.deleteFrameButton(this.frameId, id).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The frame menu button is successfully deleted!', { keepAfterRouteChange: true });
          this.prepareFrameButtonsMenuData();
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleActiveButton(buttonIndex){
    this.showSpinnerForm = true;
    this.buttonAction = 'edit';
    this.button.next(this.frameButtonsValue[buttonIndex]);
    this.showSpinnerForm = false;
  }

  handleAdd(){
    this.showSpinnerForm = true;
    this.buttonAction = 'add';
    this.buttonService.getEmptyButton().subscribe((x: any) => {
      this.buttonValue = x;
      let buttonColumns = this.buttonValue['stationcard_button'];
      let buttonMeta = buttonColumns.metadata;
      if(buttonMeta){
        buttonMeta = JSON.parse(buttonMeta);
      }
      this.buttonValue['metadata'] = buttonMeta;
      this.frameButtonId = this.buttonValue['id'];
      this.newButtonForm = this.fb.group({
        goToPageId: [this.buttonValue.goToPageId],
        buttonId: [this.buttonValue['buttonId']],
        position: [this.buttonValue.position],
        eventToListenToShow: [this.buttonValue.eventToListenToShow],
        eventToListenToHide: [this.buttonValue.eventToListenToHide],
        eventToListenToEnable: [this.buttonValue.eventToListenToEnable],
        eventToListenToDisable: [this.buttonValue.eventToListenToDisable]
      });
      this.showSpinnerForm = false;
    });
  }

  addForm(formValue: Button){
    let newButton: any = {};
    newButton.goToPageId = (formValue.goToPageId) ? formValue.goToPageId : 0;
    newButton.buttonId = formValue['buttonId'];
    newButton.position = formValue.position;
    let metadata = null;
    this.buttonTypesValue.forEach(element => {
      if(element['id'] === parseInt(newButton.buttonId)){
        metadata = element['metadata'];
      }
    });
    newButton.metadata = metadata;
    newButton.eventToListenToShow = formValue.eventToListenToShow;
    newButton.eventToListenToHide = formValue.eventToListenToHide;
    newButton.eventToListenToEnable = formValue.eventToListenToEnable;
    newButton.eventToListenToDisable = formValue.eventToListenToDisable;

    this.showSpinnerForm = true;
    this.framesService.addFrameButton(this.frameId, newButton).pipe(first()).subscribe({
      next: (response: any) => {
        this.alertService.success('New button is successfully added!', { keepAfterRouteChange: true });
        if(this.frameButtons){
          this.frameButtons.next(response);
          if(response.length){
            if(this.button){
              this.button.next(response[0]);
            }else{
              this.button = new BehaviorSubject<Button>(response[0]);
            }
          }else{
            if(this.button){
              this.button.next(null);
            }else{
              this.button = new BehaviorSubject<Button>(null);
            }
          }
        }else{
          this.frameButtons = new BehaviorSubject<Button[]>(response);
        }
        this.buttonAction = 'edit';
        const queryParams: Params = { activeTab: 2 };
        this.router.navigate(
          [], 
          {
            relativeTo: this.route,
            queryParams: queryParams, 
            queryParamsHandling: 'merge',
          }
        );
        this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

}
