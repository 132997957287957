import { NgModule } from '@angular/core';
import { MatToolbarModule} from '@angular/material/toolbar';
import { MatButtonModule} from '@angular/material/button'; 
import { MatCardModule} from '@angular/material/card';
import { MatIconModule} from '@angular/material/icon';
import { MatMenuModule} from '@angular/material/menu';
import { MatDividerModule} from '@angular/material/divider';
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material/select';
import { MatInputModule} from '@angular/material/input';
import { MatProgressBarModule} from '@angular/material/progress-bar';
import { MatSidenavModule} from '@angular/material/sidenav';
import { MatListModule} from '@angular/material/list';
import { MatDialogModule} from '@angular/material/dialog';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';

import { MatGridListModule } from '@angular/material';
import { MatTabsModule } from '@angular/material';
import { MatRadioModule } from '@angular/material';
import { MatButtonToggleModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material';

@NgModule({
    declarations: [
        
    ],
    imports: [
      MatToolbarModule, MatButtonModule, MatCardModule, MatIconModule, MatMenuModule, MatDividerModule,
      MatSnackBarModule, MatFormFieldModule, MatInputModule, MatProgressBarModule, MatSidenavModule,
      MatListModule, MatDialogModule, MatDatepickerModule, MatSelectModule, MatTooltipModule,
      MatPaginatorModule, MatTableModule, MatCheckboxModule, MatChipsModule, MatGridListModule, MatTabsModule, MatRadioModule, MatButtonToggleModule, MatAutocompleteModule
    ],
    exports: [
      MatToolbarModule, MatButtonModule, MatCardModule, MatIconModule, MatMenuModule, MatDividerModule,
      MatSnackBarModule, MatFormFieldModule, MatInputModule, MatProgressBarModule, MatSidenavModule,
      MatListModule, MatDialogModule, MatDatepickerModule, MatSelectModule, MatTooltipModule,
      MatPaginatorModule, MatTableModule, MatCheckboxModule, MatChipsModule, MatGridListModule, MatTabsModule, MatRadioModule, MatButtonToggleModule, MatAutocompleteModule
    ]
})

export class MaterialModule {

}