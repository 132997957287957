import { Component, OnInit, Input } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PagesService } from 'src/app/services/pages.service';

import { Page } from 'src/app/objects/page';
import { ListsService } from 'src/app/objects/lists';
import { first } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { FrameService } from 'src/app/services/frame.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-pages-editor',
  templateUrl: './pages-editor.component.html',
  styleUrls: ['./pages-editor.component.css']
})
export class PagesEditorComponent implements OnInit {

  @Input() page: any;
  @Input() framePages: any;
  @Input() contentRefresh: any;

  framePagesValue: [] = null;
  pageValue: any = null;

  pageForm: FormGroup = null;
  newPageForm: FormGroup = null;

  errorMessage: string = '';
  showSpinnerForm: boolean = true;
  public events: any;
  public actions: any;

  public pageID: any = null;
  public pageAction: string = 'edit';

  public stationId: any = null;
  public frameId: any = null;

  constructor(
  public pagesService: PagesService,
  public framesService: FrameService, 
  public lists: ListsService, 
  private settingsService: SettingsService,
  private fb: FormBuilder,
  private alertService: AlertService,
  private route: ActivatedRoute,
  private router: Router) { 
    this.route.queryParams.subscribe(params => {
      this.stationId = params['stationId'];
      this.frameId = params['id'];
    });
  }

  ngOnChanges(): void {
    if(this.framePages){
      this.framePages.subscribe((x: any) => {
        this.framePagesValue = x;
      });
    }

    if(this.page){
      this.page.subscribe((x) => {
        if(x){
          this.pageValue = x;
          if(this.pageValue['stationcard_page']){
            let pageColumns = this.pageValue['stationcard_page'];
            this.pageValue.description = pageColumns.description;
            this.pageValue.type = pageColumns.type;
            let pageMeta = pageColumns.metadata;
            if(pageMeta){
              pageMeta = JSON.parse(pageMeta);
              this.pageValue.title = ('title' in pageMeta) ? pageMeta['title'] : '';
            }
          }
          this.pageValue.isDisplayFirst = Boolean(Number(this.pageValue.isDisplayFirst));
          if(this.pageValue.customEvents){
            let customEvents = JSON.parse(this.pageValue.customEvents);
            if(customEvents && ('events' in customEvents)){
              this.pageValue.eventToListen = ('eventToListen' in customEvents.events[0]) ? customEvents.events[0]['eventToListen'] : null;
              this.pageValue.action = ('action' in customEvents.events[0]) ? customEvents.events[0]['action'] : null;
            }
          }
          this.pageID = this.pageValue['pageId'];
          this.populateForm();
          if(!this.framePagesValue || !this.framePagesValue.length){
            this.pageAction = 'delete';
          }else{
            this.pageAction = 'edit';
          }
        }
        this.showSpinnerForm = false;
      });
    }

    this.showSpinnerForm = false;
  }

  ngOnInit() {
    this.settingsService.getSettingsByType('events_list').subscribe((res: any) => {
      if(res){
        this.events = JSON.parse(res['metadata']);
      }
    });
    this.lists.getActionsList().subscribe((items: any) => {
      this.actions = items;
    });
  }

  populateForm(){
    this.createForm();
  }

  createForm() {
    this.pageForm = this.fb.group({
      id: [{value: this.pageValue['pageId'], disabled: true}, Validators.required],
      title: [this.pageValue.title],
      description: [this.pageValue.description],
      type: [this.pageValue.type, Validators.required],
      isDisplayFirst: [this.pageValue.isDisplayFirst, Validators.required],
      triggerEvent1: [this.pageValue.triggerEvent1],
      triggerEvent2: [this.pageValue.triggerEvent2],
      eventToListenToShow: [this.pageValue.eventToListenToShow],
      eventToListenToHide: [this.pageValue.eventToListenToHide],
      eventToListenToEnable: [this.pageValue.eventToListenToEnable],
      eventToListenToDisable: [this.pageValue.eventToListenToDisable],
      eventToListen: [this.pageValue.eventToListen],
      action: [this.pageValue.action]
    });
  }

  saveForm(formValue: any){
    let metadata:any = {};
    metadata.title = formValue.title;
    let updatePage: any = {};
    updatePage.description = formValue.description;
    updatePage.type = formValue.type;
    updatePage.metadata = JSON.stringify(metadata);
    updatePage.isDisplayFirst = formValue.isDisplayFirst;
    updatePage.triggerEvent1 = formValue.triggerEvent1;
    updatePage.triggerEvent2 = formValue.triggerEvent2;
    updatePage.eventToListenToShow = formValue.eventToListenToShow;
    updatePage.eventToListenToHide = formValue.eventToListenToHide;
    updatePage.eventToListenToEnable = formValue.eventToListenToEnable;
    updatePage.eventToListenToDisable = formValue.eventToListenToDisable;
    updatePage.customEvents = JSON.stringify({"events": [{"eventToListen": formValue.eventToListen, "action": formValue.action}]});
    
    this.showSpinnerForm = true;
    this.framesService.updateFramePage(this.frameId, this.pageID, updatePage).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The page is successfully updated!', { keepAfterRouteChange: true });
          if(this.framePages){
            this.framePages.next(response);
            if(response.length){
              if(this.page){
                this.page.next(response[0]);
              }else{
                this.page = new BehaviorSubject<Page>(response[0]);
              }
            }else{
              if(this.page){
                this.page.next(null);
              }else{
                this.page = new BehaviorSubject<Page>(null);
              }
            }
          }else{
            this.framePages = new BehaviorSubject<Page[]>(response);
          }
          const queryParams: Params = { activeTab: 1 };
          this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: queryParams, 
              queryParamsHandling: 'merge',
            }
          );
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleDelete(){
    this.showSpinnerForm = true;
    this.framesService.deleteFramePage(this.frameId, this.pageID).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('The frame page is successfully deleted!', { keepAfterRouteChange: true });
          if(this.framePages){
            this.framePages.next(response);
            if(response.length){
              if(this.page){
                this.page.next(response[0]);
              }else{
                this.page = new BehaviorSubject<Page>(response[0]);
              }
            }else{
              if(this.page){
                this.page.next(null);
              }else{
                this.page = new BehaviorSubject<Page>(null);
              }
            }
          }else{
            this.framePages = new BehaviorSubject<Page[]>(response);
          }
          const queryParams: Params = { activeTab: 1 };
          this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: queryParams, 
              queryParamsHandling: 'merge',
            }
          );
          if(!this.framePagesValue || !this.framePagesValue.length){
            this.pageAction = 'delete';
          }else{
            this.pageAction = 'edit';
          }
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

  handleActivePage(pageIndex){
    this.showSpinnerForm = true;
    this.pageAction = 'edit';
    this.page.next(this.framePagesValue[pageIndex]);
    this.showSpinnerForm = false;
  }

  handleAdd(){
    this.showSpinnerForm = true;
    this.pageAction = 'add';
    this.pagesService.getEmptyPage().subscribe((x) => {
      this.pageValue = x;
      this.pageID = x.id;
      this.newPageForm = this.fb.group({
        title: [this.pageValue.title],
        description: [this.pageValue.description],
        type: [this.pageValue.type, Validators.required],
        isDisplayFirst: [this.pageValue.isDisplayFirst],
        triggerEvent1: [this.pageValue.triggerEvent1],
        triggerEvent2: [this.pageValue.triggerEvent2],
        eventToListenToShow: [this.pageValue.eventToListenToShow],
        eventToListenToHide: [this.pageValue.eventToListenToHide],
        eventToListenToEnable: [this.pageValue.eventToListenToEnable],
        eventToListenToDisable: [this.pageValue.eventToListenToDisable],
        eventToListen: [this.pageValue.eventToListen],
        action: [this.pageValue.action]
      });
      this.showSpinnerForm = false;
    });
  }

  addForm(formValue: any){
    let metadata:any = {};
    metadata.title = formValue.title;
    let newPage: any = {};
    newPage.description = formValue.description;
    newPage.type = formValue.type;
    newPage.metadata = JSON.stringify(metadata);
    newPage.isDisplayFirst = formValue.isDisplayFirst;
    newPage.triggerEvent1 = formValue.triggerEvent1;
    newPage.triggerEvent2 = formValue.triggerEvent2;
    newPage.eventToListenToShow = formValue.eventToListenToShow;
    newPage.eventToListenToHide = formValue.eventToListenToHide;
    newPage.eventToListenToEnable = formValue.eventToListenToEnable;
    newPage.eventToListenToDisable = formValue.eventToListenToDisable;
    newPage.customEvents = JSON.stringify({"events": [{"eventToListen": formValue.eventToListen, "action": formValue.action}]});
    
    // stop here if form is invalid
    if (this.newPageForm.invalid) {
        //return;
    }
    this.showSpinnerForm = true;
    this.framesService.addFramePage(this.frameId, newPage).pipe(first()).subscribe({
      next: (response:any) => {
          this.alertService.success('New page is successfully added!', { keepAfterRouteChange: true });
          if(this.framePages){
            this.framePages.next(response);
            if(response.length){
              if(this.page){
                this.page.next(response[0]);
              }else{
                this.page = new BehaviorSubject<Page>(response[0]);
              }
            }else{
              if(this.page){
                this.page.next(null);
              }else{
                this.page = new BehaviorSubject<Page>(null);
              }
            }
          }else{
            this.framePages = new BehaviorSubject<Page[]>(response);
          }
          this.pageAction = 'edit';
          const queryParams: Params = { activeTab: 1 };
          this.router.navigate(
            [], 
            {
              relativeTo: this.route,
              queryParams: queryParams, 
              queryParamsHandling: 'merge',
            }
          );
          this.showSpinnerForm = false;
      },
      error: error => {
          this.alertService.error(error, { keepAfterRouteChange: true });
          this.showSpinnerForm = false;
      }
    });
  }

}
