import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ButtonsService {

  baseURL = environment.apiURL + '/buttons';

  public emptyButton = {
    id: 0,
    buttonId: 1,
    stationcard_button: {
      metadata: null,
    },
    position: "topLeft",
    metadata: null,
    eventToListenToShow: null,
    eventToListenToHide: null,
    eventToListenToEnable:null,
    eventToListenToDisable: null
  };

  constructor(private http: HttpClient) { }

  getEmptyButton(){
    return of(this.emptyButton);
  }

  getAllButtons(){
    return this.http.get<any[]>(`${this.baseURL}`,{ withCredentials: true });
  }
  
}
