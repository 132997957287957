import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { AppComponent } from './app.component';
import { JwtInterceptor, ErrorInterceptor } from './helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BrowseComponent } from './components/browse/browse.component';
import { EditorComponent } from './components/editor/editor.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ConfirmationDialogComponent } from './components/confirm-dialog/confirmation-dialog.component';
import { FrameEditorComponent } from './components/frame-editor/frame-editor.component';
import { PagesEditorComponent } from './components/pages-editor/pages-editor.component';
import { ButtonsEditorComponent } from './components/buttons-editor/buttons-editor.component';
import { ComponentsEditorComponent } from './components/components-editor/components-editor.component';
import { MobilePreviewerComponent } from './components/mobile-previewer/mobile-previewer.component';
import { AddFrameComponent } from './components/add-frame/add-frame.component';
import { AlertComponent } from './components/alert/alert.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { FrameAssociationsComponent } from './components/frame-associations/frame-associations.component';

import { SafePipe } from './helpers/safe.pipe';
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    SidebarComponent,
    BrowseComponent,
    EditorComponent,
    SettingsComponent,
    ConfirmationDialogComponent,
    FrameEditorComponent,
    PagesEditorComponent,
    ButtonsEditorComponent,
    ComponentsEditorComponent,
    MobilePreviewerComponent,
    AddFrameComponent,
    AlertComponent,
    NoAccessComponent,
    FrameAssociationsComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragDropModule
  ],
  entryComponents: [ConfirmationDialogComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5000} }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
