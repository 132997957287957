import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../objects/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseURL = environment.apiURL + '/accounts';

  private accountSubject: BehaviorSubject<any>;
  public account: Observable<User>;

  constructor(
      private router: Router,
      private http: HttpClient
  ) {
        let mapaAccountUser = null;
        mapaAccountUser = this.getSessionInfo();
        this.accountSubject = new BehaviorSubject<User>(mapaAccountUser);
        this.account = this.accountSubject.asObservable();
    }

    getSessionInfo(){
        if(this.getCookie('mapaAccountUser') && this.getCookie('mapaAccountUser') != ""){
          const userSessionInfo = JSON.parse(this.getCookie('mapaAccountUser'));
          return userSessionInfo;
        }else{
          return null;
        }
    }

    getCookie(cname){
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' '){
                c = c.substring(1);
            }
    
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    public get accountValue(): any {
        return this.accountSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${this.baseURL}/authenticate`, { email, password }, { withCredentials: true })
            .pipe(map(account => {
                this.accountSubject.next(account);
                this.setCookie("mapaAccountUser", JSON.stringify(account), 1);
                this.startRefreshTokenTimer();
                return account;
            }));
    }

    logout() {
        this.http.post<any>(`${this.baseURL}/revoke-token`, {}, { withCredentials: true }).subscribe();
        this.stopRefreshTokenTimer();
        this.accountSubject.next(null);
        this.setCookie("mapaAccountUser", "", 1);
        this.router.navigate(['/login']);
    }

    refreshToken() {
        return this.http.post<any>(`${this.baseURL}/refresh-token`, {}, { withCredentials: true })
            .pipe(map((account) => {
                this.accountSubject.next(account);
                this.startRefreshTokenTimer();
                return account;
            }));
    }

    register(account: User) {
      return this.http.post(`${this.baseURL}/register`, account);
    }

    verifyEmail(token: string) {
      return this.http.post(`${this.baseURL}/verify-email`, { token });
    }
    
    forgotPassword(email: string) {
        return this.http.post(`${this.baseURL}/forgot-password`, { email });
    }
    
    validateResetToken(token: string) {
        return this.http.post(`${this.baseURL}/validate-reset-token`, { token });
    }
    
    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${this.baseURL}/reset-password`, { token, password, confirmPassword });
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

}
